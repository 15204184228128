
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IconButton, Button, Typography, Box } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

export const ProfileMenu = ({profile}) => {
  const history = useHistory();
  const isImpersonated = Boolean(profile?.impersonated);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const gotoProfile = () => {
    handleProfileClose();
    history.push("/me");
  };

  return profile
    ? <IconButton
        data-cy="menu-profile"
        sx={{ fontSize: "100%" }}
        onClick={handleProfileClick}
        color="inherit"
        size="large">
        <Box>
          <Typography sx={{lineHeight: 0.8, textAlign: "right"}}>{(isImpersonated ? "Impersonating " : "") + (profile.name.startsWith("o@") ? "" : profile.name)}</Typography>
          <Typography sx={{fontVariant: "small-caps", lineHeight: 0.8, textAlign: "right"}}>{profile.participant?.name || "admin"}</Typography>
        </Box>
        &nbsp;
        <AccountCircle />
      </IconButton>
    : <Button data-cy="nav-login" size="small" color="inherit" onClick={() => window.location.href="/api/auth/login?after-login" + encodeURIComponent(window.location.href) }>Login</Button>
};
