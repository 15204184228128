
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, Card, CardActions, CardHeader, CardContent, Typography, Box, Divider } from '@mui/material';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useDraftApi } from "../../hooks/draftApi";
import { useServiceApi } from "../../hooks/serviceApi";
import { FormRow } from "../widgets/customComponents";

const ServiceCards = ({services, onView}) => <Grid container spacing={2} sx={{padding: 2}}>
  {services.map((s, i) => <Grid key={`pending${i}`} item xs={12} sm={6} lg={4}>
    <Card variant="outlined" sx={{ borderRadius: '24px' }} key={`service{i}`}>
      <CardContent>
        <Typography variant="h7">{s.display_name || "NEW"}</Typography>
        <Typography variant="caption" component="div">{s.modified && new Date(s.modified).toLocaleDateString() || null}</Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => onView(s)} size="small">View</Button>
        <Box sx={{marginLeft: 'auto'}}>
          {(s.disabled || null) && <ClearOutlinedIcon sx={{width: '18px'}} />}
          {(!s.disabled && s.experimental || null) && <ScienceOutlinedIcon sx={{marginLeft: 'auto', width: '18px'}} />}
        </Box>
      </CardActions>
    </Card>
  </Grid>)}
</Grid>;

export default () => {
  const history = useHistory();
  const draftApi = useDraftApi();
  const serviceApi = useServiceApi();
  const [services, setServices] = useState(null);
  const [drafts, setDrafts] = useState(null);

  useEffect(() => {
    if (services) return;

    serviceApi.listServices(setServices);
    draftApi.listServices(setDrafts);
  }, [Boolean(services)]);

  const handleNew = () => draftApi.createService({}, (draft) => history.push(`/services/${draft.id}/draft`));
  const handleViewDraft = (s) => history.push(`/services/${s.id}/draft`);
  const handleViewService = (s) => history.push(`/services/${s.id}`);

  if (!services && !drafts) return null;

  return <>
    <FormRow>
      <Typography variant="h6">Published</Typography>
      <Button onClick={handleNew} size="small" sx={{width: 'auto'}}>New</Button>
    </FormRow>
    {services?.length ? <ServiceCards services={services} onView={handleViewService} /> : <i>None</i>}
    {(drafts?.length || null) && <>
      <Divider sx={{marginTop: '20px', marginBottom: '10px'}} />
      <Typography variant="h6">Drafts</Typography>
      <ServiceCards services={drafts} onView={handleViewDraft} />
    </>}
  </>;
};
