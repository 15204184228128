import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../hooks/useApi";
import { displayErrorMessage, displayInfoMessage } from "../redux/modules/messages";
import { serializeToJsonapiEntity, deserializeJsonapiEntity, deserializeJsonapiEntities } from "../util/jsonapi";

export const useDraftApi = () => {
  const dispatch = useDispatch();

  ////////////////// list participants

  const listParticipantsCallback = useRef(null);
  const [listParticipantsRes, apiListParticipants] = useApi();

  const listParticipants = (callback) => {
    listParticipantsCallback.current = callback;
    apiListParticipants("resource/participant_drafts?fields[participant_drafts]=id,organization,display_name,modified,review_state,original_id");
  };

  useEffect(() => {
    if (listParticipantsRes.status) {
      const callback = listParticipantsCallback.current;
      listParticipantsCallback.current = null;
      if (listParticipantsRes.status >= 300) {
        console.log("draftApi.listParticipants", listParticipantsRes);
        dispatch(displayErrorMessage("Failed to list draft participants", listParticipantsRes));
      } else if (callback) {
        callback(deserializeJsonapiEntities(listParticipantsRes.data));
      }
    }
  }, [listParticipantsRes]);

  ////////////////// Get participant

  const getParticipantCallback = useRef(null);
  const [getParticipantRes, apiGetParticipant] = useApi();

  const getParticipant = (id, callback) => {
    getParticipantCallback.current = callback;
    apiGetParticipant(`resource/participant_drafts/${id}`);
  };

  useEffect(() => {
    if (getParticipantRes.status) {
      const callback = getParticipantCallback.current;
      getParticipantCallback.current = null;
      if (getParticipantRes.status >= 300) {
        console.log("draftApi.getParticipant", getParticipantRes);
        dispatch(displayErrorMessage("Failed to retrieve draft participant", getParticipantRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(getParticipantRes.data));
      }
    }
  }, [getParticipantRes]);

  ////////////////// Get my draft participant

  const getMyParticipantCallback = useRef(null);
  const [getMyParticipantRes, apiGetMyParticipant] = useApi();

  const getMyParticipant = (callback) => {
    getMyParticipantCallback.current = callback;
    apiGetMyParticipant("drafts/participants");
  };

  useEffect(() => {
    if (getMyParticipantRes.status) {
      const callback = getMyParticipantCallback.current;
      getMyParticipantCallback.current = null;
      if (getMyParticipantRes.status >= 300) {
        console.log("draftApi.getMyParticipant", getMyParticipantRes);
        dispatch(displayErrorMessage("Failed to retrieve my draft participant", getMyParticipantRes));
      } else if (callback) {
        callback(getMyParticipantRes.data && deserializeJsonapiEntity(getMyParticipantRes.data) || null);
      }
    }
  }, [getMyParticipantRes]);

  ////////////////// Update participant

  const updateParticipantCallback = useRef(null);
  const [updateParticipantRes, apiUpdateParticipant] = useApi("PATCH");

  const updateParticipant = (id, attributes, callback) => {
    updateParticipantCallback.current = callback;
    const payload = serializeToJsonapiEntity(id, "participant_drafts", attributes);
    apiUpdateParticipant(`resource/participant_drafts/${id}`, payload);
  };

  useEffect(() => {
    if (updateParticipantRes.status) {
      const callback = updateParticipantCallback.current;
      updateParticipantCallback.current = null;
      if (updateParticipantRes.status >= 300) {
        console.log("draftApi.updateParticipant", updateParticipantRes);
        dispatch(displayErrorMessage("Failed to update draft participant", updateParticipantRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(updateParticipantRes.data));
      }
    }
  }, [updateParticipantRes]);

  ////////////////// Delete participant

  const deleteParticipantCallback = useRef(null);
  const [deleteParticipantRes, apiDeleteParticipant] = useApi("DELETE");

  const deleteParticipant = (id, callback) => {
    deleteParticipantCallback.current = callback;
    apiDeleteParticipant(`resource/participant_drafts/${id}`);
  };

  useEffect(() => {
    if (deleteParticipantRes.status) {
      const callback = deleteParticipantCallback.current;
      deleteParticipantCallback.current = null;
      if (deleteParticipantRes.status >= 300) {
        console.log("draftApi.deleteParticipant", deleteParticipantRes);
        dispatch(displayErrorMessage("Failed to delete draft participant", deleteParticipantRes));
      } else if (callback) {
        callback();
      }
    }
  }, [deleteParticipantRes]);

  ////////////////// Reedit participant

  const reeditParticipantCallback = useRef(null);
  const [reeditParticipantRes, apiReeditParticipant] = useApi("POST");

  const reeditParticipant = (callback) => {
    reeditParticipantCallback.current = callback;
    apiReeditParticipant("drafts/participants/reedit");
  };

  useEffect(() => {
    if (reeditParticipantRes.status) {
      const callback = reeditParticipantCallback.current;
      reeditParticipantCallback.current = null;
      if (reeditParticipantRes.status >= 300) {
        console.log("draftApi.reeditParticipant", reeditParticipantRes);
        dispatch(displayErrorMessage("Failed to reedit participant", reeditParticipantRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(reeditParticipantRes.data));
      }
    }
  }, [reeditParticipantRes]);

  ////////////////// Submit participant

  const submitParticipantCallback = useRef(null);
  const [submitParticipantRes, apiSubmitParticipant] = useApi("POST");

  const submitParticipant = (comment, callback) => {
    submitParticipantCallback.current = callback;
    apiSubmitParticipant("drafts/participants/submit", comment, {"Content-Type": "text/plain"});
  };

  useEffect(() => {
    if (submitParticipantRes.status) {
      const callback = submitParticipantCallback.current;
      submitParticipantCallback.current = null;
      if (submitParticipantRes.status >= 300) {
        console.log("draftApi.submitParticipant", submitParticipantRes);
        dispatch(displayErrorMessage("Failed to submit participant draft", submitParticipantRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(submitParticipantRes.data));
      }
    }
  }, [submitParticipantRes]);

  ////////////////// Retract participant

  const retractParticipantCallback = useRef(null);
  const [retractParticipantRes, apiRetractParticipant] = useApi("POST");

  const retractParticipant = (callback) => {
    retractParticipantCallback.current = callback;
    apiRetractParticipant("drafts/participants/retract");
  };

  useEffect(() => {
    if (retractParticipantRes.status) {
      const callback = retractParticipantCallback.current;
      retractParticipantCallback.current = null;
      if (retractParticipantRes.status >= 300) {
        console.log("draftApi.retractParticipant", retractParticipantRes);
        dispatch(displayErrorMessage("Failed to retract participant draft", retractParticipantRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(retractParticipantRes.data));
      }
    }
  }, [retractParticipantRes]);

  ////////////////// Approve participant

  const approveParticipantCallback = useRef(null);
  const [approveParticipantRes, apiApproveParticipant] = useApi("POST");

  const approveParticipant = (id, callback) => {
    approveParticipantCallback.current = callback;
    apiApproveParticipant(`drafts/participants/approve?draft_id=${id}`);
  };

  useEffect(() => {
    if (approveParticipantRes.status) {
      const callback = approveParticipantCallback.current;
      approveParticipantCallback.current = null;
      if (approveParticipantRes.status >= 300) {
        console.log("draftApi.approveParticipant", approveParticipantRes);
        dispatch(displayErrorMessage("Failed to approveParticipant draft", approveParticipantRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(approveParticipantRes.data));
      }
    }
  }, [approveParticipantRes]);

  ////////////////// Reject participant

  const rejectParticipantCallback = useRef(null);
  const [rejectParticipantRes, apiRejectParticipant] = useApi("POST");

  const rejectParticipant = (id, comment, callback) => {
    rejectParticipantCallback.current = callback;
    apiRejectParticipant(`drafts/participants/reject?draft_id=${id}`, comment, {"Content-Type": "text/plain"});
  };

  useEffect(() => {
    if (rejectParticipantRes.status) {
      const callback = rejectParticipantCallback.current;
      rejectParticipantCallback.current = null;
      if (rejectParticipantRes.status >= 300) {
        console.log("draftApi.rejectParticipant", rejectParticipantRes);
        dispatch(displayErrorMessage("Failed to reject draft", rejectParticipantRes));
      } else if (callback) {
        callback();
      }
    }
  }, [rejectParticipantRes]);

  ////////////////// List services

  const listServicesCallback = useRef(null);
  const [listServicesRes, apiListServices] = useApi();

  const listServices = (callback) => {
    listServicesCallback.current = callback;
    apiListServices("resource/service_drafts?fields[service_drafts]=id,name,display_name,modified,review_state,original_id");
  };

  useEffect(() => {
    if (listServicesRes.status) {
      const callback = listServicesCallback.current;
      listServicesCallback.current = null;
      if (listServicesRes.status >= 300) {
        console.log("draftApi.listServices", listServicesRes);
        dispatch(displayErrorMessage("Failed to retrieve list draft services", listServicesRes));
      } else if (callback) {
        callback(deserializeJsonapiEntities(listServicesRes.data));
      }
    }
  }, [listServicesRes]);

  ////////////////// Get service

  const getServiceCallback = useRef(null);
  const [getServiceRes, apiGetService] = useApi();

  const getService = (id, callback) => {
    getServiceCallback.current = callback;
    apiGetService(`resource/service_drafts/${id}`);
  };

  useEffect(() => {
    if (getServiceRes.status) {
      const callback = getServiceCallback.current;
      getServiceCallback.current = null;
      if (getServiceRes.status >= 300) {
        console.log("draftApi.getService", getServiceRes);
        dispatch(displayErrorMessage("Failed to retrieve draft service", getServiceRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(getServiceRes.data));
      }
    }
  }, [getServiceRes]);

  ////////////////// Create service

  const createServiceCallback = useRef(null);
  const [createServiceRes, apiCreateService] = useApi("POST");

  const createService = (attributes, callback) => {
    createServiceCallback.current = callback;
    const payload = serializeToJsonapiEntity(null, "service_drafts", attributes);
    apiCreateService("resource/service_drafts", payload);
  };

  useEffect(() => {
    if (createServiceRes.status) {
      const callback = createServiceCallback.current;
      createServiceCallback.current = null;
      if (createServiceRes.status >= 300) {
        console.log("draftApi.createService", createServiceRes);
        dispatch(displayErrorMessage("Failed to create service", createServiceRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(createServiceRes.data));
      }
    }
  }, [createServiceRes]);

  ////////////////// Update service

  const updateServiceCallback = useRef(null);
  const [updateServiceRes, apiUpdateService] = useApi("PATCH");

  const updateService = (id, attributes, callback) => {
    updateServiceCallback.current = callback;
    const payload = serializeToJsonapiEntity(id, "service_drafts", attributes);
    apiUpdateService(`resource/service_drafts/${id}`, payload);
  };

  useEffect(() => {
    if (updateServiceRes.status) {
      const callback = updateServiceCallback.current;
      updateServiceCallback.current = null;
      if (updateServiceRes.status >= 300) {
        console.log("draftApi.updateService", updateServiceRes);
        dispatch(displayErrorMessage("Failed to update service", updateServiceRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(updateServiceRes.data));
      }
    }
  }, [updateServiceRes]);

  ////////////////// Delete service

  const deleteServiceCallback = useRef(null);
  const [deleteServiceRes, apiDeleteService] = useApi("DELETE");

  const deleteService = (id, callback) => {
    deleteServiceCallback.current = callback;
    apiDeleteService(`resource/service_drafts/${id}`);
  };

  useEffect(() => {
    if (deleteServiceRes.status) {
      const callback = deleteServiceCallback.current;
      deleteServiceCallback.current = null;
      if (deleteServiceRes.status >= 300) {
        console.log("draftApi.deleteService", deleteServiceRes);
        dispatch(displayErrorMessage("Failed to delete draft service", deleteServiceRes));
      } else if (callback) {
        callback();
      }
    }
  }, [deleteServiceRes]);

  ////////////////// Reedit service

  const reeditServiceCallback = useRef(null);
  const [reeditServiceRes, apiReeditService] = useApi("POST");

  const reeditService = (id, callback) => {
    reeditServiceCallback.current = callback;
    apiReeditService(`drafts/services/reedit?service_id=${id}`);
  };

  useEffect(() => {
    if (reeditServiceRes.status) {
      const callback = reeditServiceCallback.current;
      reeditServiceCallback.current = null;
      if (reeditServiceRes.status >= 300) {
        console.log("draftApi.reeditService", reeditServiceRes);
        dispatch(displayErrorMessage("Failed to reedit service", reeditServiceRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(reeditServiceRes.data));
      }
    }
  }, [reeditServiceRes]);

  ////////////////// Submit service

  const submitServiceCallback = useRef(null);
  const [submitServiceRes, apiSubmitService] = useApi("POST");

  const submitService = (id, comment, callback) => {
    submitServiceCallback.current = callback;
    apiSubmitService(`drafts/services/submit?draft_id=${id}`, comment, {"Content-Type": "text/plain"});
  };

  useEffect(() => {
    if (submitServiceRes.status) {
      const callback = submitServiceCallback.current;
      submitServiceCallback.current = null;
      if (submitServiceRes.status >= 300) {
        console.log("draftApi.submitService", submitServiceRes);
        dispatch(displayErrorMessage("Failed to submit draft", submitServiceRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(submitServiceRes.data));
      }
    }
  }, [submitServiceRes]);

  ////////////////// Retract service

  const retractServiceCallback = useRef(null);
  const [retractServiceRes, apiRetractService] = useApi("POST");

  const retractService = (id, callback) => {
    retractServiceCallback.current = callback;
    apiRetractService(`drafts/services/retract?draft_id=${id}`);
  };

  useEffect(() => {
    if (retractServiceRes.status) {
      const callback = retractServiceCallback.current;
      retractServiceCallback.current = null;
      if (retractServiceRes.status >= 300) {
        console.log("draftApi.retractService", retractServiceRes);
        dispatch(displayErrorMessage("Failed to retract draft", retractServiceRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(retractServiceRes.data));
      }
    }
  }, [retractServiceRes]);

  ////////////////// Approve service

  const approveServiceCallback = useRef(null);
  const [approveServiceRes, apiApproveService] = useApi("POST");

  const approveService = (id, callback) => {
    approveServiceCallback.current = callback;
    apiApproveService(`drafts/services/approve?draft_id=${id}`);
  };

  useEffect(() => {
    if (approveServiceRes.status) {
      const callback = approveServiceCallback.current;
      approveServiceCallback.current = null;
      if (approveServiceRes.status >= 300) {
        console.log("draftApi.approveService", approveServiceRes);
        dispatch(displayErrorMessage("Failed to approve draft", approveServiceRes));
      } else if (callback) {
        callback();
      }
    }
  }, [approveServiceRes]);

  ////////////////// Reject service

  const rejectServiceCallback = useRef(null);
  const [rejectServiceRes, apiRejectService] = useApi("POST");

  const rejectService = (id, comment, callback) => {
    rejectServiceCallback.current = callback;
    apiRejectService(`drafts/services/reject?draft_id=${id}`, comment, {"Content-Type": "text/plain"});
  };

  useEffect(() => {
    if (rejectServiceRes.status) {
      const callback = rejectServiceCallback.current;
      rejectServiceCallback.current = null;
      if (rejectServiceRes.status >= 300) {
        console.log("draftApi.rejectService", rejectServiceRes);
        dispatch(displayErrorMessage("Failed to reject draft", rejectServiceRes));
      } else if (callback) {
        callback();
      }
    }
  }, [rejectServiceRes]);

  return { listParticipants, getParticipant, getMyParticipant, updateParticipant, deleteParticipant, reeditParticipant, submitParticipant, retractParticipant, approveParticipant, rejectParticipant, listServices, getService, reeditService, createService, updateService, deleteService, reeditService, submitService, retractService, retractService, approveService, rejectService };
};
