
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export const LeftNav = ({navItems, path, onNav}) => <List sx={{paddingTop: 0}}>
  {navItems.map((n, i) => <ListItem disablePadding key={`nav${i}`}>
    <ListItemButton onClick={() => onNav(n.path)} selected={path.includes(n.path)}>
      <ListItemIcon>{n.icon}</ListItemIcon>
      <ListItemText primary={n.name} />
    </ListItemButton>
  </ListItem>)}
</List>;
