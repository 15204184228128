
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import ApiIcon from '@mui/icons-material/Api';
import BusinessIcon from '@mui/icons-material/Business';
import ExtensionIcon from '@mui/icons-material/Extension';
import { useAuth } from "../../AuthContext";
import { LeftNav } from "../widgets/LeftNav";
import MyAccount from "./MyAccount";
import MyServices from "./MyServices";
import MyService from "./MyService";
import MainTemplates from "../common/MainTemplates";
import MainTemplate from "../common/MainTemplate";
import MyProfile from "../common/MyProfile";

export const ParticipantRoute = ({ children, ...rest}) => {
  const { profile } = useAuth();

  return profile.participant ? <Route {...rest}>{children}</Route> : "Not authorized";
};

const navItems = [
  { name: "My Account", path: "/myaccount", icon: <BusinessIcon /> },
  { name: "My Services", path: "/services", icon: <ExtensionIcon /> },
  { name: "Templates", path: "/templates", icon: <ApiIcon /> }
];

const ParticipantSidebar = (path) => {
  const history = useHistory();
  const { profile } = useAuth();

  const onNav = (nav) => history.push(nav);

  if (profile.participant)
    return <LeftNav navItems={navItems} path={path} onNav={onNav} />;

  return <LeftNav navItems={[navItems[0], navItems[2]]} path={path} onNav={onNav} />;
};

const ParticipantMain = () => {
  const { profile } = useAuth();

  return <Switch>
    <Route path="/myaccount/draft">
      <MyAccount isDraft={true} />
    </Route>
    <Route path="/myaccount">
      <MyAccount isDraft={false} />
    </Route>
    <ParticipantRoute profile={profile} path="/services/:serviceId/draft">
      <MyService isDraft={true} />
    </ParticipantRoute>
    <ParticipantRoute profile={profile} path="/services/:serviceId">
      <MyService isDraft={false} />
    </ParticipantRoute>
    <ParticipantRoute profile={profile} path="/services">
      <MyServices />
    </ParticipantRoute>
    <Route path="/templates/:templateId">
      <MainTemplate />
    </Route>
    <Route path="/templates">
      <MainTemplates />
    </Route>
    <Route path="/me">
      <MyProfile />
    </Route>
    <Route>
      <Redirect to={Boolean(profile.participant) ? "/myaccount" : "/myaccount/draft" } />
    </Route>
  </Switch>;
};

export default () => ({ sidebar: ParticipantSidebar, main: ParticipantMain });