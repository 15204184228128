
import { combineReducers } from "redux";
import modal from "./modal";
import messages from "./messages";
import registry from "./registry";

export const reducer = combineReducers({
  modal,
  messages,
  registry
});

export const RootState = reducer;
