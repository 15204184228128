
import axios from "axios";
import { useEffect, useState, useCallback, useRef } from "react";
import { useAuth } from '../../AuthContext';

const hook = (method) => {
  const [apiRes, setApiRes] = useState({status: 0, data: null, error: null});
  const { profile, setProfile } = useAuth();

  const sendRequest = useCallback(
    async (url, requestData, headers) => {
      setApiRes({status: 0, data: null, error: null});
      const config = {
        baseURL: "/api",
        url,
        method: method || "GET",
        data: requestData || null,
        headers: headers || { "content-type": "application/json" },
        withCredentials: true
      };
      try {
        const response = await axios(config);
        setApiRes({status: response.status, data: response.data, error: null});
      } catch (e) {
        setApiRes({status: e.response?.status || 500, data: null, error: e.response?.data || "Unknown error"});
      }
    },
    []
  );

  useEffect(() => {
    if (profile && apiRes.status === 401) {
      setProfile({ status: 401 });
    }
  }, [profile, setProfile, apiRes.status]);

  return [apiRes, sendRequest];
};

export default hook;

