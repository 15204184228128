
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import ApiIcon from '@mui/icons-material/Api';
import BusinessIcon from '@mui/icons-material/Business';
import ExtensionIcon from '@mui/icons-material/Extension';
import { LeftNav } from "../widgets/LeftNav";
import MainParticipants from "./MainParticipants";
import MainParticipant from "./MainParticipant";
import MainServices from "./MainServices";
import MainService from "./MainService";
import MainTemplates from "../common/MainTemplates";
import MainTemplate from "../common/MainTemplate";
import MyProfile from "../common/MyProfile";

const navItems = [
  { name: "Participants", path: "/participants", icon: <BusinessIcon /> },
  { name: "Services", path: "/services", icon: <ExtensionIcon /> },
  { name: "Templates", path: "/templates", icon: <ApiIcon /> }
];

const AdminSidebar = (path) => {
  const history = useHistory();
  const onNav = (nav) => history.push(nav);

  return <LeftNav navItems={navItems} path={path} onNav={onNav} />;
}

const AdminMain = () => <Switch>
  <Route path="/participants/:participantId/draft">
    <MainParticipant isDraft={true} />
  </Route>
  <Route path="/participants/:participantId">
    <MainParticipant isDraft={false} />
  </Route>
  <Route path="/participants">
    <MainParticipants />
  </Route>
  <Route path="/services/:serviceId/draft">
    <MainService isDraft={true} />
  </Route>
  <Route path="/services/:serviceId">
    <MainService isDraft={false} />
  </Route>
  <Route path="/services">
    <MainServices />
  </Route>
  <Route path="/templates/:templateId">
    <MainTemplate />
  </Route>
  <Route path="/templates">
    <MainTemplates />
  </Route>
  <Route path="/me">
    <MyProfile />
  </Route>
  <Route>
    <Redirect to="/participants" />
  </Route>
</Switch>;

export default () => ({ sidebar: AdminSidebar, main: AdminMain });