
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Alert, List, ListItem, ListItemText, ListItemButton, TextField, Button, Typography, Box } from '@mui/material';
import { useServiceApi } from "../../hooks/serviceApi";
import {Form, FormRow} from "../widgets/customComponents";

const ViewTemplate = ({template, onViewService, onSpecs}) => <Form>
  {(template.deprecated || null) &&
    <Alert severity="warning">This template is deprecated.</Alert>
  }
  <Typography variant="h4">{template.display_name}</Typography>
  <Typography variant="h6">{template.name} ({template.version})</Typography>
  <TextField
    multiline={true}
    minRows={3}
    label="Description"
    defaultValue={template.description || ""}
    InputProps={{readOnly: true, disableUnderline: true }}
    margin="dense"
    variant="standard"
    fullWidth
  />
  <Box component="fieldset" sx={{marginTop: '15px'}}>
    <legend>Services</legend>
    {!template.services?.length
      ? "None"
      : <List>
        {template.services.map((service) => <ListItem key={`ts${service.id}`} disablePadding>
          <ListItemButton onClick={() => onViewService(service)}>
            <ListItemText primary={service.display_name} />
          </ListItemButton>
        </ListItem>)}
      </List>
    }
  </Box>

  <FormRow style={{marginTop: '30px'}}>
    <Button data-cy="button-specs" onClick={onSpecs} disableElevation>
      View Specifications
    </Button>
  </FormRow>
</Form>;

export default () => {
  const history = useHistory();
  const { templateId } = useParams();
  const serviceApi = useServiceApi();
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    if (!template) {
      serviceApi.getTemplate(templateId, setTemplate)
    }
  }, [Boolean(template)]);

  const handleViewSpecifications = () => window.open(template.specifications_url, "_blank");
  const handleViewService = (s) => history.push(`/services/${s.id}`);

  if (template == null) return;

  return <ViewTemplate template={template} onViewService={handleViewService} onSpecs={handleViewSpecifications} />
};
