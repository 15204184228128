
import { Typography } from "@mui/material";
import { useAuth } from "../../AuthContext";

const NotAuthenticated = () => {
  const { profile } = useAuth();

  if (profile.status == 401)
    return <Typography>Session expired, please <a href={`/api/auth/login?after-login=${window.location.href}`}>login</a> or <a href={`/api/auth/login?register=true&after-login=${window.location.href}`}>register</a>.</Typography>;

  return <Typography>The server is currently experiencing issues (error={profile.status}). Please try again later.</Typography>;
};

export default () => ({ sidebar: null, main: NotAuthenticated });