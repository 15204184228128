
import produce from "immer";

const initialState = {
    myParticipant: null,
    templates: null,
};

const reducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case "SET_MY_PARTICIPANT":
      draft.myParticipant = action.payload.participant;
      break;
    case "SET_TEMPLATES":
      draft.templates = action.payload.templates;
      break;
    default:
      return draft;
  }
});

export default reducer;

export const setMyParticipant = (participant) => ({ type: "SET_MY_PARTICIPANT", payload: { participant } });

export const setTemplates = (templates) => ({ type: "SET_TEMPLATES", payload: { templates } });
