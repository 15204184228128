
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert, TextField, Button, Typography, MenuItem } from '@mui/material';
import { useAuth } from "../../AuthContext";
import {Form, FormRow} from "../widgets/customComponents";
import { useValidation, NOT_EMPTY_VALIDATOR, LENGTH_VALIDATOR, URL_VALIDATOR } from "../../hooks/validation";
import { selectTemplates } from "../../redux/modules/registry/selectors";

export const ViewService = ({service, actions, onChange}) => {
  const templates = useSelector(selectTemplates);

  const templateId = service.template?.id || service.template_id;
  const template = templateId && templates ? templates.find(t => t.id == templateId) : null;
  const templateName = template ? `${template.name} (${template.version})` : !templateId ? "undefined" : "loading" ;

  return <Form>
    {(service.enabled == false || null) &&
      <Alert severity="error">This service is disabled.</Alert>
    }
    {(service.enabled != false && service.experimental || null) &&
      <Alert severity="warning">This service is currently experimental.</Alert>
    }
    <Typography variant="h4">{service.display_name}</Typography>
    <FormRow>
      <Typography variant="caption">{service.name}</Typography>
      <Typography variant="caption" sx={{marginLeft: 'auto'}}>
        Last modified on: {new Date(service.modified).toLocaleDateString()}
      </Typography>
    </FormRow>
    <TextField
      label="Template"
      defaultValue={templateName}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
    />
    <TextField
      label="Description"
      multiline={true}
      minRows={3}
      defaultValue={service.description || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
    />
    <FormRow>
      <TextField
        label="Website URL"
        defaultValue={service.website_url || "-"}
        InputProps={{readOnly: true, disableUnderline: true }}
        margin="dense"
        variant="standard"
        sx={{width: '50%'}}
      />
      <TextField
        label="Service Configuration URL"
        defaultValue={service.configuration_url || "-"}
        InputProps={{readOnly: true, disableUnderline: true }}
        margin="dense"
        variant="standard"
        sx={{width: '50%'}}
      />
    </FormRow>
    <TextField
      label="Service Base URL"
      defaultValue={service.service_base_url || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
    />
    <TextField
      label="OAuth2 Auth URL"
      defaultValue={service.oauth2_auth_url || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
    />
    <TextField
      label="OAuth2 Token URL"
      defaultValue={service.oauth2_token_url || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
    />
    <TextField
      label="OAuth2 Scopes (space-separated)"
      defaultValue={service.oauth2_scopes || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
    />
    <FormRow>
      <TextField
        label="ATLAS OAuth2 Client ID"
        defaultValue={service.oauth2_client_id || "-"}
        InputProps={{readOnly: true, disableUnderline: true }}
        margin="dense"
        variant="standard"
        sx={{width:'50%'}}
      />
      <TextField
        label="ATLAS OAuth2 Client SECRET"
        defaultValue={service.oauth2_client_secret || "-"}
        InputProps={{readOnly: true, disableUnderline: true }}
        margin="dense"
        variant="standard"
        sx={{width:'50%'}}
      />
    </FormRow>
    {(Boolean(actions) || null) && actions(service, onChange)}
  </Form>;
};

const VALIDATION_OPTIONS = {
  name: {
    validateUndef: true,
    validator: NOT_EMPTY_VALIDATOR
  },
  template_id: {
    validateUndef: true,
    validator: NOT_EMPTY_VALIDATOR
  },
  display_name: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(3, 100)
  },
  description: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(3, 5000)
  },
  website_url: {
    validateUndef: true,
    validator: URL_VALIDATOR
  },
  configuration_url: {
    validateUndef: true,
    validator: URL_VALIDATOR
  },
  service_base_url: {
    validateUndef: true,
    validator: [LENGTH_VALIDATOR(1, 300), URL_VALIDATOR]
  },
  oauth2_token_url: {
    validateUndef: true,
    validator: [LENGTH_VALIDATOR(1, 300), URL_VALIDATOR]
  },
  oauth2_auth_url: {
    validateUndef: true,
    validator: [LENGTH_VALIDATOR(1, 300), URL_VALIDATOR]
  },
  oauth2_scopes: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(100)
  },
  oauth2_client_id: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(3, 100)
  },
  oauth2_client_secret: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(3, 100)
  }
};

export const EditDraft = ({draft: original, actions, onChange}) => {
  const { profile } = useAuth();
  const isAdmin = profile.roles.includes("ADMIN");
  const validation = useValidation(isAdmin ? VALIDATION_OPTIONS : {...VALIDATION_OPTIONS, name: {}});
  const templates = useSelector(selectTemplates);
  const [draft, setDraft] = useState({...original});

  useEffect(() => validation.init(draft), []);

  const handlePropChange = (prop, v) => {
    const value = validation.validate(prop, v);
    if (value === undefined) return;
    setDraft({...draft, [prop]: value});
  };

  const handleChange = (e) => {
    handlePropChange(e.target.name, e.target.value);
  };

  const handleNewDraft = (draft) => {
    validation.init(draft);
    onChange(draft);
  }

  const inReview = draft.review_state == 'REVIEWING';
  const isNew = !draft.original_id;
  const isExperimental = Boolean(draft.experimental);

  return <Form>
    {(draft.review_state == "REJECTED" || null) && <Alert severity="warning">
      Your submission has been rejected: {draft.review_comment || "unknown reason"}
    </Alert>}
    <TextField
      name="name"
      label={`Service Identifier${isExperimental ? " (currently experimental)" : ""}`}
      value={draft.name || ""}
      helperText={!draft.name ? "will be set by administrator": ""}
      onChange={handleChange}
      error={!validation.isValid("name")}
      InputProps={{readOnly: (!isNew && !isExperimental) || !isAdmin, disableUnderline: (!isNew && !isExperimental) || !isAdmin }}
      margin="dense"
      variant="standard"
      fullWidth
    />
    <TextField
      name="template_id"
      label="Template"
      value={templates && draft.template_id || ""}
      onChange={handleChange}
      error={!validation.isValid("template_id")}
      InputProps={{readOnly: !isNew && !isExperimental, disableUnderline: !isNew && !isExperimental }}
      select={isNew || isExperimental}
      margin="dense"
      variant="standard"
    >
      {(isNew || isExperimental) && templates &&
        templates.map((t) => <MenuItem key={t.id} value={t.id}>{t.display_name} ({t.version})</MenuItem>)
      }
    </TextField>
    <TextField
      name="display_name"
      label="Display Name"
      value={draft.display_name || ""}
      onChange={handleChange}
      error={!validation.isValid("display_name")}
      margin="dense"
      variant="standard"
      fullWidth
    />
    <TextField
      name="description"
      label="Description"
      value={draft.description || ""}
      multiline={true}
      minRows={3}
      onChange={handleChange}
      error={!validation.isValid("description")}
      margin="dense"
      variant="standard"
    />
    <FormRow>
      <TextField
        name="website_url"
        label="Website URL"
        value={draft.website_url || ""}
        onChange={handleChange}
        error={!validation.isValid("website_url")}
        margin="dense"
        variant="standard"
        sx={{width: '50%'}}
      />
      <TextField
        name="configuration_url"
        label="Service Configuration URL"
        value={draft.configuration_url || ""}
        onChange={handleChange}
        error={!validation.isValid("configuration_url")}
        margin="dense"
        variant="standard"
        sx={{width: '50%'}}
      />
    </FormRow>
    <TextField
      name="service_base_url"
      label="Service Base URL"
      value={draft.service_base_url || ""}
      onChange={handleChange}
      InputProps={{readOnly: !isNew || !isExperimental, disableUnderline: !isNew || !isExperimental }}
      error={!validation.isValid("service_base_url")}
      margin="dense"
      variant="standard"
    />
    <TextField
      name="oauth2_auth_url"
      label="OAuth2 Auth URL"
      value={draft.oauth2_auth_url || ""}
      onChange={handleChange}
      error={!validation.isValid("oauth2_auth_url")}
      margin="dense"
      variant="standard"
    />
    <TextField
      name="oauth2_token_url"
      label="OAuth2 Token URL"
      value={draft.oauth2_token_url || ""}
      onChange={handleChange}
      error={!validation.isValid("oauth2_token_url")}
      margin="dense"
      variant="standard"
    />
    <TextField
      name="oauth2_scopes"
      label="OAuth2 Scopes (space-separated)"
      value={draft.oauth2_scopes || ""}
      onChange={handleChange}
      error={!validation.isValid("oauth2_scopes")}
      margin="dense"
      variant="standard"
    />
    <FormRow>
      <TextField
        name="oauth2_client_id"
        label="ATLAS OAuth2 Client ID"
        value={draft.oauth2_client_id || ""}
        onChange={handleChange}
        error={!validation.isValid("oauth2_client_id")}
        margin="dense"
        variant="standard"
        sx={{width: '50%'}}
      />
      <TextField
        name="oauth2_client_secret"
        label="ATLAS OAuth2 Client SECRET"
        value={draft.oauth2_client_secret || ""}
        onChange={handleChange}
        error={!validation.isValid("oauth2_client_secret")}
        margin="dense"
        variant="standard"
        sx={{width: '50%'}}
      />
    </FormRow>
    {actions(draft, validation, handleNewDraft)}
  </Form>;
};
