
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../AuthContext";
import { setMyParticipant, setTemplates } from "../../redux/modules/registry";
import { useParticipantApi } from "../participantApi";
import { useServiceApi } from "../serviceApi";

const hook = (): void => {
  const dispatch = useDispatch();
  const { profile } = useAuth();
  const participantApi = useParticipantApi();
  const serviceApi = useServiceApi();

  useEffect(() => {
    if (!profile?.roles) return;
    serviceApi.listTemplates((templates) => dispatch(setTemplates(templates)));
    if (profile.participant)
      participantApi.getParticipant(profile.participant.id, (participant) =>
        dispatch(setMyParticipant(participant)));
  }, [Boolean(profile?.roles)]);
};

export default hook;
