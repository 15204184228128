
import { useState, useEffect, createContext } from "react";
import { Provider, useSelector, useDispatch} from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, Container, Button } from "@mui/material";
import { useAuth } from "./AuthContext";
import { useUserApi } from "./hooks/userApi";
import useRegistry from "./hooks/useRegistry";
import configureStore from "./redux/store";
import NotAuthenticated from "./components/notauthenticated";
import AdminAccount from "./components/adminAccount";
import ParticipantAccount from "./components/participantAccount";
import { Layout } from "./components/widgets/Layout";

const App = () => {
  const dispatch = useDispatch();
  const userApi = useUserApi();
  const [delayed, setDelayed] = useState(true);
  const { profile, setProfile } = useAuth();

  useEffect(() => {
    if (!profile) {
      userApi.getProfile((p) => {
        if (p.error) {
          setProfile({status: p.status});
        } else
          setProfile(p);
      });

      const timeout = setTimeout(() => setDelayed(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [profile]);

  useRegistry();

  if (profile == null) return delayed ? null : "Loading...";

  const account = !profile.id
                   ? NotAuthenticated
                   : profile.roles?.includes("ADMIN")
                     ? AdminAccount
                     : ParticipantAccount;

  return <BrowserRouter basename={"/"}>
    <CssBaseline />
    <Layout account={account()} />
  </BrowserRouter>
};

export default () => <Provider store={configureStore()}><App/></Provider>;
