import produce from "immer";

const initialState: MessagesState = { items: [] };

/* eslint-disable consistent-return, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case "ADD_ERROR_MESSAGE":
        draft.items = [...draft.items, { message: action.payload.message, isErrorMessage: true }];
        break;
      case "ADD_INFO_MESSAGE":
        draft.items = [...draft.items, { message: action.payload.message, isErrorMessage: false }];
        break;
      case "POP_MESSAGE":
        if (draft.items.length > 0) draft.items = draft.items.slice(1);
        break;
      case "CLEAR_MESSAGES":
        draft.items = [];
        break;
      default:
        return draft;
    }
  });
/* eslint-enable consistent-return, no-param-reassign */

export default reducer;

export const displayErrorMessage = (message, error) => ({
  type: "ADD_ERROR_MESSAGE",
  payload: { message: `${message} - ${error?.error?.message || "unknown error"}` }
});

export const displayInfoMessage = (message) => ({
  type: "ADD_INFO_MESSAGE",
  payload: { message },
});

export const popMessage = () => ({ type: "POP_MESSAGE" });

export const clearMessages = () => ({ type: "CLEAR_MESSAGES" });
