import React, { memo, lazy, Suspense, createContext, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, CircularProgress, Box } from "@mui/material";
import { closeModal } from "../../../redux/modules/modal";
import { selectModalParams } from "../../../redux/modules/modal/selectors";

const fallback = <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress size={30} /></Box>;

const ModalWrapper = memo(() => {
  const dispatch = useDispatch();
  const { componentPath, componentParams, modalSettings = { maxWidth: "sm"}, open } = useSelector(selectModalParams);
  const [currentPath, setCurrentPath] = useState(null);

  useEffect(() => setCurrentPath(open ? componentPath : componentPath == currentPath ? null : componentPath), [open, componentPath]);

  const isOpen = Boolean(currentPath);

  const onClose = () => dispatch(closeModal());

  const dialogBody = useMemo(() => {
    if (!isOpen) return null;
    const BodyComponent = lazy(() => import(`../../../${componentPath}`));
    return <BodyComponent params={componentParams} />
  }, [componentPath, isOpen]);

  return <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth={modalSettings.maxWidth}
      classes={{ paper: "paper" }}
    >
      <ModalContext.Provider value={{closeModal: onClose}}>
        <Suspense fallback={fallback}>{dialogBody}</Suspense>
      </ModalContext.Provider>
    </Dialog>
});

export default ModalWrapper;
export const ModalContext = createContext({});
