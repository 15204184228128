import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../hooks/useApi";
import { displayErrorMessage, displayInfoMessage } from "../redux/modules/messages";
import { deserializeJsonapiEntity, deserializeJsonapiEntities } from "../util/jsonapi";

export const useServiceApi = () => {
  const dispatch = useDispatch();

  ////////////////// List services

  const listServicesCallback = useRef(null);
  const [listServicesRes, apiListServices] = useApi();

  const listServices = (callback) => {
    listServicesCallback.current = callback;
    apiListServices("resource/services?fields[services]=id,name,display_name,enabled,experimental&sort=enabled,experimental,name");
  };

  useEffect(() => {
    if (listServicesRes.status) {
      const callback = listServicesCallback.current;
      listServicesCallback.current = null;
      if (listServicesRes.status >= 300) {
        console.log("serviceApi.listServices", listServicesRes);
        dispatch(displayErrorMessage("Failed to list services", listServicesRes));
      } else if (callback) {
        callback(deserializeJsonapiEntities(listServicesRes.data));
      }
    }
  }, [listServicesRes]);


  ////////////////// Get service

  const getServiceCallback = useRef(null);
  const [getServiceRes, apiGetService] = useApi();

  const getService = (id, callback) => {
    getServiceCallback.current = callback;
    apiGetService(`resource/services/${id}`);
  };

  useEffect(() => {
    if (getServiceRes.status) {
      const callback = getServiceCallback.current;
      getServiceCallback.current = null;
      if (getServiceRes.status >= 300) {
        console.log("serviceApi.getService", getServiceRes);
        dispatch(displayErrorMessage("Failed to retrieve service", getServiceRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(getServiceRes.data));
      }
    }
  }, [getServiceRes]);

  ////////////////// enable/disable participant

  const setServiceEnablementCallback = useRef(null);
  const [setServiceEnablementRes, apiSetServiceEnablement] = useApi("POST");

  const setServiceEnablement = (id, enable, callback) => {
    setServiceEnablementCallback.current = callback;
    apiSetServiceEnablement(`resource/services/${id}/enablement?enable=${enable}`);
  };

  useEffect(() => {
    if (setServiceEnablementRes.status) {
      const callback = setServiceEnablementCallback.current;
      setServiceEnablementCallback.current = null;
      if (setServiceEnablementRes.status >= 300) {
        console.log("participantApi.setServiceEnablement", setServiceEnablementRes);
        dispatch(displayErrorMessage("Failed to set enablement", setServiceEnablementRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(setServiceEnablementRes.data));
      }
    }
  }, [setServiceEnablementRes]);

  ////////////////// service experimental

  const setServiceExperimentalCallback = useRef(null);
  const [setServiceExperimentalRes, apiSetServiceExperimental] = useApi("POST");

  const setServiceExperimental = (id, experimental, callback) => {
    setServiceExperimentalCallback.current = callback;
    apiSetServiceExperimental(`resource/services/${id}/experimental?experimental=${experimental}`);
  };

  useEffect(() => {
    if (setServiceExperimentalRes.status) {
      const callback = setServiceExperimentalCallback.current;
      setServiceExperimentalCallback.current = null;
      if (setServiceExperimentalRes.status >= 300) {
        console.log("participantApi.setServiceExperimental", setServiceExperimentalRes);
        dispatch(displayErrorMessage("Failed to set experimental", setServiceExperimentalRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(setServiceExperimentalRes.data));
      }
    }
  }, [setServiceExperimentalRes]);

  ////////////////// list templates

  const listTemplatesCallback = useRef(null);
  const [listTemplatesRes, apiListTemplates] = useApi();

  const listTemplates = (callback) => {
    listTemplatesCallback.current = callback;
    apiListTemplates("resource/templates?fields[templates]=id,name,version,display_name,created&sort=name,version");
  };

  useEffect(() => {
    if (listTemplatesRes.status) {
      const callback = listTemplatesCallback.current;
      listTemplatesCallback.current = null;
      if (listTemplatesRes.status >= 300) {
        console.log("serviceApi.listTemplates", listTemplatesRes);
        dispatch(displayErrorMessage("Failed to list templates", listTemplatesRes));
      } else if (callback) {
        callback(deserializeJsonapiEntities(listTemplatesRes.data));
      }
    }
  }, [listTemplatesRes]);

  ////////////////// Get template

  const getTemplateCallback = useRef(null);
  const [getTemplateRes, apiGetTemplate] = useApi();

  const getTemplate = (id, callback) => {
    getTemplateCallback.current = callback;
    apiGetTemplate(`resource/templates/${id}?include=services`);
  };

  useEffect(() => {
    if (getTemplateRes.status) {
      const callback = getTemplateCallback.current;
      getTemplateCallback.current = null;
      if (getTemplateRes.status >= 300) {
        console.log("serviceApi.getTemplate", getTemplateRes);
        dispatch(displayErrorMessage("Failed to retrieve template", getTemplateRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(getTemplateRes.data));
      }
    }
  }, [getTemplateRes]);

  return { listServices, getService, listTemplates, getTemplate, setServiceEnablement, setServiceExperimental };
};
