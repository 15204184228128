
import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, Card, CardActions, CardHeader, CardContent, Typography, Chip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useDraftApi } from "../../hooks/draftApi";
import { useParticipantApi } from "../../hooks/participantApi";
import {Tabs, Tab} from "../widgets/customComponents";
import { Participants, Drafts } from "./participants"


export default () => {
  const history = useHistory();
  const draftApi = useDraftApi();
  const participantApi = useParticipantApi();
  const [tab, setTab] = useState("active");
  const [participants, setParticipants] = useState(null);
  const [drafts, setDrafts] = useState(null);
  const [counts, setCounts] = useState(null);

  useEffect(() => {
    if (participants) return;

    participantApi.listParticipants((participants) => {
      var newCounts = {active: 0, disabled: 0, untrusted: 0, EDITING: 0, REVIEWING: 0, REJECTED: 0};
      participants.forEach(p => {
        const type = !p.enabled ? "disabled" : p.untrusted ? "untrusted" : "active";
        newCounts[type] += 1;
      });
      setCounts(newCounts);
      setParticipants(participants);
      draftApi.listParticipants((drafts) => {
        drafts.forEach(s => newCounts[s.review_state] += 1);
        setCounts(newCounts);
        setDrafts(drafts);
      });
    });
  }, [Boolean(participants)]);

  const handleTabChange = (e, newTab) => setTab(newTab);
  const handleView = (p) => history.push(`/participants/${p.id}`);
  const handleReview = (p) => history.push(`/participants/${p.id}/draft`);

  if (participants == null && drafts == null) return;

  return <>
    <Tabs value={tab} onChange={handleTabChange}>
      <Tab value="active" label={`Published (${counts?.active || 0})`} />}
      {(counts?.untrusted || null) && <Tab value="untrusted" label={`Untrusted (${counts.untrusted})`} />}}
      {(counts?.disabled || null) && <Tab value="disabled" label={`Disabled (${counts.disabled})`} />}}
      {(counts?.REVIEWING || null) && <Tab value="reviewing" label={`Pending approval (${counts.REVIEWING})`} />}
    </Tabs>

    {(tab == "active" || tab == "untrusted" || tab == "disabled")
      ? <Participants participants={participants || []} state={tab} onView={handleView} />
      : <Drafts drafts={drafts} onView={handleReview} />}
  </>;
};
