import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../hooks/useApi";
import { displayErrorMessage, displayInfoMessage } from "../redux/modules/messages";
import { deserializeJsonapiEntity } from "../util/jsonapi";

export const useUserApi = () => {
  const dispatch = useDispatch();

  ////////////////// Get profile

  const getProfileCallback = useRef(null);
  const [getProfileRes, apiGetProfile] = useApi();

  const getProfile = (callback) => {
    getProfileCallback.current = callback;
    apiGetProfile("myprofile");
  };

  useEffect(() => {
    if (getProfileRes.status) {
      const callback = getProfileCallback.current;
      getProfileCallback.current = null;
      if (getProfileRes.status == 401) {
        callback({status: 401, error: "unauthorized"});
      } else if (getProfileRes.status >= 300) {
        console.log("userApi.getProfile", getProfileRes);
        callback({status: getProfileRes.status, error: getProfileRes.error, correlation_id: getProfileRes?.correlation_id || null});
      } else {
        callback(deserializeJsonapiEntity(getProfileRes.data));
      }
    }
  }, [getProfileRes]);


  ////////////////// Change password

  const [changePasswordRes, apiChangePassword] = useApi("POST");
  const changePasswordCallback = useRef(null);
  const changePassword = (currentPassword, newPassword, callback) => {
    changePasswordCallback.current = callback;
    apiChangePassword("change_password", {old_password: currentPassword, new_password: newPassword});
  }

  useEffect(() => {
    if (changePasswordRes.status) {
      const callback = changePasswordCallback.current;
      changePasswordCallback.current = null;
      if (changePasswordRes.status >= 300) {
        console.log("userApi.changePassword", changePasswordRes);
        dispatch(displayErrorMessage("Failed to change password", changePasswordRes));
      } else if (callback) {
        dispatch(displayInfoMessage("Password changed"));
        callback();
      }
    }
  }, [changePasswordRes]);

  return { getProfile, changePassword };
};
