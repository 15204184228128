
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs, Tab, Button, Grid, Card, CardActions, CardHeader, CardContent, Typography } from '@mui/material';
import { selectTemplates } from "../../redux/modules/registry/selectors";

export default () => {
  const history = useHistory();
  const templates = useSelector(selectTemplates)

  const handleView = (t) => history.push(`/templates/${t.id}`);

  if (templates == null) return;

  if (templates.length == 0)
    return "No templates";

  return <Grid container spacing={2} sx={{padding: 2}}>
    {templates.map((t, i) => <Grid key={`pending${i}`} item xs={12} sm={6} lg={4}>
      <Card variant="outlined" sx={{ borderRadius: '24px' }}>
        <CardContent>
          <Typography variant="h7">{t.name}</Typography>
          <Typography variant="caption" component="div">{new Date(t.created).toLocaleDateString()}</Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => handleView(t)}>Details</Button>
        </CardActions>
      </Card>
    </Grid>)}
  </Grid>;
};
