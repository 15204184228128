
import produce from "immer";

const initialState = {
  componentPath: "",
  componentParams: {},
  modalSettings: {},
  callback: null,
  open: false
};

/* eslint-disable consistent-return, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case "SHOW_MODAL":
        draft.componentPath = action.payload.componentPath;
        draft.componentParams = action.payload.componentParams;
        draft.modalSettings = action.payload.modalSettings;
        draft.callback = action.payload.callback;
        draft.open = true;
        break;
      case "HIDE_MODAL":
        draft.componentParams = {};
        draft.modalSettings = {};
        draft.open = false;
        break;
      default:
        return draft;
    }
  });
/* eslint-enable consistent-return, no-param-reassign */

export default reducer;

export const openModal = ({ componentPath, componentParams, modalSettings, callback }) => ({
  type: "SHOW_MODAL",
  payload: {
    componentPath,
    componentParams,
    modalSettings,
    callback
  }
});

export const closeModal = () => ({ type: "HIDE_MODAL" });
