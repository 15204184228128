import { useDispatch } from "react-redux";
import { openModal, closeModal } from "../redux/modules/modal";

export const useConfirm = () => {
  const dispatch = useDispatch();

  const showModal = (title, message, onConfirm) =>
    dispatch(
      openModal({
        componentPath: "components/widgets/ConfirmationModal",
        componentParams: { title, message, onConfirm },
        modalSettings: { maxWidth: "xs" },
      })
    );
  return showModal;
};
