
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from '@mui/material';
import { usePrompt } from "../../hooks/prompt";
import { useConfirm } from "../../hooks/confirm";
import { useDraftApi } from "../../hooks/draftApi";
import { useParticipantApi } from "../../hooks/participantApi";
import { Tabs, Tab, FormRow } from "../widgets/customComponents";
import { ViewParticipant, EditDraft } from "../common/participant";


const publishedActions = (participant, onChange) => {
  const history = useHistory();
  const confirm = useConfirm();
  const prompt = usePrompt();
  const participantApi = useParticipantApi();

  const handleDelete = () => {
    confirm(null,
      "Irreversibly delete participant?",
      () => participantApi.deleteParticipant(participant.id, () => history.replace("/participants"))
    );
  };

  const handleEnablement = () => {
    confirm(null,
      participant.enabled ? "Disable participant?" : "Enable participant?",
      () => participantApi.setParticipantEnablement(participant.id, !participant.enabled, onChange)
    );
  };

  const handleTrustworthiness = () => {
    confirm(null,
      !participant.untrusted ? "Un-trust participant?" : "Trust participant?",
      () => participantApi.setParticipantTrustworthiness(participant.id, participant.untrusted, onChange)
    );
  };

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-trustworthiness"
      onClick={handleTrustworthiness}
      disableElevation
    >
      {participant.untrusted ? "Trust" : "Un-trust"}
    </Button>
    <Button
      data-cy="button-enablement"
      onClick={handleEnablement}
      disableElevation
    >
      {participant.enabled ? "Disable" : "Re-Enable"}
    </Button>
    {(!participant.enabled || null) && <Button
      data-cy="button-delete"
      onClick={handleDelete}
      disableElevation
    >
      Permanently Delete
    </Button>}
  </FormRow>
};

const viewDraftActions = (draft, onChange) => {
  const history = useHistory();
  const draftApi = useDraftApi();
  const confirm = useConfirm();

  const handleDelete = () => {
    confirm(
      null,
      "Discard participant's changes and delete draft?",
      () => draftApi.deleteParticipant(draft.id, () => {
        history.replace(`/participants/${draft.id}`);
        onChange(null);
      })
    );
  };

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-delete"
      onClick={handleDelete}
      disableElevation
    >
      Delete Un-submitted Draft
    </Button>
  </FormRow>;
};

const draftActions = (draft, validation, onNewDraft) => {
  const history = useHistory();
  const draftApi = useDraftApi();
  const confirm = useConfirm();
  const prompt = usePrompt();

  const handleSave = () => {
    draftApi.updateParticipant(draft.id, validation.modifiedProperties(draft), onNewDraft);
  };

  const handleApprove = () => {
    confirm(
      "Approve Changes?",
      `Are you sure you publish changes to the registry?`,
      () => draftApi.approveParticipant(draft.id, (participant) =>
        history.replace(`/participants/${draft.id}`))
    );
  };

  const handleReject = () => {
    prompt("Reject submission (and discard unsaved changes)?", {label: "Review comment"}, (comment) =>
      draftApi.rejectParticipant(draft.id, comment, () => history.replace(`/participants/${draft.id}`))
    );
  };

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-reject"
      onClick={handleReject}
      disableElevation
    >
      Reject Submission
    </Button>
    <Button
      data-cy="button-approve"
      onClick={handleApprove}
      disableElevation
      disabled={validation.isModified() || !validation.isValid()}
    >
      {draft.original_id ? "Approve Changes" : "Approve New Participant"}
    </Button>
    <Button
      data-cy="button-save"
      onClick={handleSave}
      disableElevation
      disabled={!validation.isModified()}
    >
      Save Changes
    </Button>
  </FormRow>;
};

export default ({isDraft}) => {
  const history = useHistory();
  const { participantId } = useParams();
  const draftApi = useDraftApi();
  const participantApi = useParticipantApi();
  const [tab, setTab] = useState(null);
  const [participant, setParticipant] = useState(null);

  useEffect(() => {
    if (isDraft) {
      draftApi.getParticipant(participantId, (draft) => {
        if (!draft) {
          history.replace("/myaccount");
          return;
        }
        setTab("review");
        setParticipant(draft);
      });
    } else {
      participantApi.getParticipant(participantId, (participant) => {
        setTab("published");
        setParticipant(participant);
      });
   }
  }, [isDraft, Boolean(participant)]);

  const handleTabChange = (e, newTab) => {
    setTab(null);
    if (newTab == "published")
      history.replace(`/participants/${participantId}`);
    else
      history.replace(`/participants/${participantId}/draft`);
  };

  if (!participant || !tab) return null;

  return <>
    {(participant.original_id || Boolean(participant.draft_state) || null) &&
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab value="published" label="Published" />
        <Tab value="review" label={participant.draft_state == "REVIEWING" ? "Review" : "Draft"} />
      </Tabs>
    }
    {tab == "published"
      ? <ViewParticipant participant={participant} actions={publishedActions} onChange={setParticipant} />
      : participant.review_state == "REVIEWING"
        ? <EditDraft draft={participant} actions={draftActions} onChange={setParticipant} />
        : <ViewParticipant participant={participant} actions={viewDraftActions} onChange={setParticipant} />
    }
  </>;
};
