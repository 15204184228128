
import { createSelector } from "reselect";

const selectModalState = (state) => state.modal;

export const selectModalParams = createSelector(
  selectModalState,
  ({ componentPath, componentParams, modalSettings, open }) => ({
    componentPath,
    componentParams,
    modalSettings,
    open
  })
);
