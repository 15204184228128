import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [profile, setMyProfile] = useState(null); // Set the initial value

  const setProfile = (p) => {
  console.log("setprofile", p);
    setMyProfile(p);
  };

  return (
    <AuthContext.Provider value={{ profile, setProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
