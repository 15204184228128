
const prepare_included_index = (included) => {
  // convert list of inclusions to dict for efficient processing
  if (!included || included.length === 0) return null;
  const reducer = (inclusions, curValue) => { inclusions[curValue.type + curValue.id] = curValue; return inclusions; };
  return included.reduce(reducer, {});
};

const deserializeJsonapiInternal = (data, inclusions)  => {
    const entity = {...data.attributes, id: data.id};
    if (!data.relationships) return entity;

    const relationships = data.relationships;
    for (const [rel_name, { data: rel_data }] of Object.entries(relationships)) {
        if (!rel_data || Array.isArray(rel_data) && rel_data.length === 0) continue;
        entity[rel_name] = rel_data;
        if (inclusions) {
            if (Array.isArray(rel_data)) {
                // Assumptions is that inclusions are all or nothing, so if first is missing, we don't expect to find any
                if (rel_data.length === 0 || !inclusions[rel_data[0].type + rel_data[0].id]) continue;
                entity[rel_name] = rel_data.map(d => {
                  const data = inclusions[d.type + d.id];
                  return deserializeJsonapiInternal(data, inclusions);
                });
            } else {
                const data = inclusions[rel_data.type + rel_data.id];
                if (data) entity[rel_name] = {id: rel_data.id, ...deserializeJsonapiInternal(data, inclusions)};
            }
        }
    }
    return entity;
};

export const deserializeJsonapiEntities = (data) => {
    const inclusions = prepare_included_index(data.included);
    return data.data.map(e => deserializeJsonapiInternal(e, inclusions));
};

export const deserializeJsonapiEntity = (data) => {
    const inclusions = prepare_included_index(data.included);
    return deserializeJsonapiInternal(data.data, inclusions);
};

export const serializeToJsonapiEntity = (id, type, attributes) => {
    const jsonEntity = { data: {  type, attributes } };
    if (id)
        jsonEntity.data.id = id;
    return jsonEntity;
}
