
import React, { useState } from "react";
import { BrowserRouter, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";
import { Toolbar, Drawer, Box, IconButton, Alert, Snackbar, Typography, Button, Divider } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuth } from "../../AuthContext";
import { selectNextMessage } from "../../redux/modules/messages/selectors";
import { popMessage } from "../../redux/modules/messages";
import { PrivateRoute } from "../widgets/routes";
import { ProfileMenu } from "../widgets/ProfileMenu";
import ModalWrapper from "./ModalWrapper";

const sidebarWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  overflow: 'auto',
  paddingLeft: '10px',
  marginLeft: `${open ? 0 : -sidebarWidth}px`
}));

const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  minWidth: '480px',
  width: `calc(100% - ${open && sidebarWidth || 0}px)`,
  maxWidth: `${1000 - (open && sidebarWidth || 0)}px`,
  left: 0,
  right: 'auto',
  marginLeft: `${open && sidebarWidth || 0}px`,
  backgroundColor: "white",
  color: "inherit",
  opacity: 0.75,
  boxShadow: "none"
}));

const SidebarHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  height: "85px",
  alignItems: 'center',
  padding: theme.spacing(0, 2.25),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

export const Layout = ({account}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const path = useLocation().pathname;
  const { profile } = useAuth();
  const { message, isErrorMessage } = useSelector(selectNextMessage);
  const [sidebarOpen, setSidebarOpen] = React.useState(true);

  const onCloseMessage = () => dispatch(popMessage());

  const handleSidebarOpen = () => setSidebarOpen(true);

  const handleSidebarClose = () => setSidebarOpen(false);

  const handleProfile = () => history.push("/me");

  const handleLogout = () => window.location.href = "/api/auth/logout";

  return <Box sx={{ display: 'flex', minWidth: '480px', maxWidth: '1000px' }}>
    <AppBar position="fixed" open={sidebarOpen && Boolean(account.sidebar)}>
      <Toolbar style={{padding: 0, marginTop: '5px'}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          style={{opacity: sidebarOpen ? 0 : 100 }}
          onClick={Boolean(account.sidebar) && !sidebarOpen ? handleSidebarOpen : null}
        >
          <MenuIcon />&nbsp;
        </IconButton>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
          ATLAS Participant Portal
        </Typography>
        <a data-cy="nav-home" href="/">
          <img src="atlas_logo.png" style={{width: 60}}/>
        </a>
      </Toolbar>
    </AppBar>
    <Snackbar open={Boolean(message)} onClose={onCloseMessage} autoHideDuration={4000}>
      <Alert onClose={onCloseMessage} severity={isErrorMessage ? "error" : "success"} sx={{ width: "100%" }}>{message}</Alert>
    </Snackbar>
    <ModalWrapper />
    {account.sidebar && <Drawer
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          borderRight: "none"
        },
      }}
      variant="persistent"
      anchor="left"
      open={sidebarOpen}
    >
      <SidebarHeader>
        <IconButton onClick={handleSidebarClose}>
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          data-cy="button-profile"
          onClick={handleProfile}
          size="small"
          sx={{ marginLeft: "auto", fontSize: "75%", lineHeight: 0.8, textAlign: "right"}}
        >
          <Box>
            <div>{profile.name}</div>
            {(profile.participant || profile.roles.includes("ADMIN") || null) && <div style={{fontVariant: "small-caps"}}>{profile.participant?.organization || "admin"}</div>}
          </Box>
          <AccountCircleIcon />
        </IconButton>
      </SidebarHeader>
      {account.sidebar(path)}
      <Divider sx={{ marginTop: "30px", marginLeft: 3, marginRight: 3 }} />
      <Button onClick={handleLogout} sx={{marginBottom: 3}}>Logout</Button>
    </Drawer>}
    {(Boolean(account.main) || null) && <Main open={sidebarOpen}>
      <SidebarHeader />
      {account.main()}
    </Main>}
  </Box>;
};

