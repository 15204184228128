import React from 'react';
import { styled } from '@mui/material/styles';
import { Tabs as OriginalTabs, Tab as OriginalTab, Stack } from '@mui/material';

export const Tabs = styled(OriginalTabs)({
  marginBottom: '16px',
});

export const Tab = styled(OriginalTab)({
  fontSize: '70%',
});

export const Form = ({children, ...props}) => <Stack spacing={2} sx={{width: '100%' }} {...props}>
  {children}
</Stack>;

export const FormRow = ({children, ...props}) => <Stack
  direction="row"
  spacing={2}
  alignItems="center"
  justifyContent="space-between"
  {...props}
>
  {children}
</Stack>;
