import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../hooks/useApi";
import { displayErrorMessage, displayInfoMessage } from "../redux/modules/messages";
import { deserializeJsonapiEntity, deserializeJsonapiEntities } from "../util/jsonapi";

export const useParticipantApi = () => {
  const dispatch = useDispatch();

  ////////////////// list participants

  const listParticipantsCallback = useRef(null);
  const [listParticipantsRes, apiListParticipants] = useApi();

  const listParticipants = (callback) => {
    listParticipantsCallback.current = callback;
    apiListParticipants("resource/participants?fields[participants]=id,created,organization,display_name,enabled,untrusted&sort=name");
  };

  useEffect(() => {
    if (listParticipantsRes.status) {
      const callback = listParticipantsCallback.current;
      listParticipantsCallback.current = null;
      if (listParticipantsRes.status >= 300) {
        console.log("participantApi.listParticipants", listParticipantsRes);
        dispatch(displayErrorMessage("Failed to list participants", listParticipantsRes));
      } else if (callback) {
        callback(deserializeJsonapiEntities(listParticipantsRes.data));
      }
    }
  }, [listParticipantsRes]);

  ////////////////// Get participant

  const getParticipantCallback = useRef(null);
  const [getParticipantRes, apiGetParticipant] = useApi();

  const getParticipant = (id, callback) => {
    getParticipantCallback.current = callback;
    apiGetParticipant(`resource/participants/${id}`);
  };

  useEffect(() => {
    if (getParticipantRes.status) {
      const callback = getParticipantCallback.current;
      getParticipantCallback.current = null;
      if (getParticipantRes.status >= 300) {
        console.log("participantApi.getParticipant", getParticipantRes);
        dispatch(displayErrorMessage("Failed to retrieve participants", getParticipantRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(getParticipantRes.data));
      }
    }
  }, [getParticipantRes]);

  ////////////////// self-unregister participant

  const unregisterParticipantCallback = useRef(null);
  const [unregisterParticipantRes, apiUnregisterParticipant] = useApi("POST");

  const unregisterParticipant = (id, callback) => {
    unregisterParticipantCallback.current = callback;
    apiUnregisterParticipant(`resource/participants/${id}/unregister`);
  };

  useEffect(() => {
    if (unregisterParticipantRes.status) {
      const callback = unregisterParticipantCallback.current;
      unregisterParticipantCallback.current = null;
      if (unregisterParticipantRes.status >= 300) {
        console.log("participantApi.unregisterParticipant", unregisterParticipantRes);
        dispatch(displayErrorMessage("Failed to unregister", unregisterParticipantRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(unregisterParticipantRes.data));
      }
    }
  }, [unregisterParticipantRes]);

  ////////////////// enable/disable participant

  const setParticipantEnablementCallback = useRef(null);
  const [setParticipantEnablementRes, apiSetParticipantEnablement] = useApi("POST");

  const setParticipantEnablement = (id, enable, callback) => {
    setParticipantEnablementCallback.current = callback;
    apiSetParticipantEnablement(`resource/participants/${id}/enablement?enable=${enable}`);
  };

  useEffect(() => {
    if (setParticipantEnablementRes.status) {
      const callback = setParticipantEnablementCallback.current;
      setParticipantEnablementCallback.current = null;
      if (setParticipantEnablementRes.status >= 300) {
        console.log("participantApi.setParticipantEnablement", setParticipantEnablementRes);
        dispatch(displayErrorMessage("Failed to set enablement", setParticipantEnablementRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(setParticipantEnablementRes.data));
      }
    }
  }, [setParticipantEnablementRes]);

  ////////////////// participant trustworthiness

  const setParticipantTrustworthinessCallback = useRef(null);
  const [setParticipantTrustworthinessRes, apiSetParticipantTrustworthiness] = useApi("POST");

  const setParticipantTrustworthiness = (id, trusted, callback) => {
    setParticipantTrustworthinessCallback.current = callback;
    apiSetParticipantTrustworthiness(`resource/participants/${id}/trustworthiness?trusted=${trusted}`);
  };

  useEffect(() => {
    if (setParticipantTrustworthinessRes.status) {
      const callback = setParticipantTrustworthinessCallback.current;
      setParticipantTrustworthinessCallback.current = null;
      if (setParticipantTrustworthinessRes.status >= 300) {
        console.log("participantApi.setParticipantTrustworthiness", setParticipantTrustworthinessRes);
        dispatch(displayErrorMessage("Failed to set trustworthiness", setParticipantTrustworthinessRes));
      } else if (callback) {
        callback(deserializeJsonapiEntity(setParticipantTrustworthinessRes.data));
      }
    }
  }, [setParticipantTrustworthinessRes]);

  ////////////////// Get credentials

  const getCredentialsCallback = useRef(null);
  const [getCredentialsRes, apiGetCredentials] = useApi();

  const getCredentials = (id, callback) => {
    getCredentialsCallback.current = callback;
    apiGetCredentials(`resource/participants/${id}/credentials`);
  };

  useEffect(() => {
    if (getCredentialsRes.status) {
      const callback = getCredentialsCallback.current;
      getCredentialsCallback.current = null;
      if (getCredentialsRes.status >= 300) {
        console.log("participantApi.getCredentials", getCredentialsRes);
        dispatch(displayErrorMessage("Failed to get credentials", getCredentialsRes));
      } else if (callback) {
        callback(getCredentialsRes.data);
      }
    }
  }, [getCredentialsRes]);

  return { listParticipants, getParticipant, unregisterParticipant, setParticipantEnablement, setParticipantTrustworthiness, getCredentials };
};
