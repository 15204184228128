
import React, { useMemo } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, Card, CardActions, CardHeader, CardContent, Typography } from '@mui/material';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const Services = ({services, state, onView}) => {
  const filtered = useMemo(() => {
    if (services == null) return [];
    return services.filter(s => {
      switch (state) {
        case "disabled": return !s.enabled;
        case "active": return s.enabled && !s.experimental;
        case "experimental": return s.enabled && s.experimental;
      }
    });
  }, [services, state]);

  return <TableContainer>
    <Table size="small">
      <colgroup>
        <col width="250" />
        <col />
        <col width="50" />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(!filtered?.length || null) && <TableRow>
          <TableCell colSpan={3}>No active services</TableCell>
        </TableRow>}
        {(filtered?.length || null) && filtered.map((s, i) => <TableRow key={`approved${i}`}>
          <TableCell>{s.name}</TableCell>
          <TableCell>{s.display_name}</TableCell>
          <TableCell><Button size="small" onClick={() => onView(s)}>Details</Button></TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  </TableContainer>;
};

export const Drafts = ({drafts, onView}) => {
  const filtered = useMemo(() => drafts.filter(s => s.review_state == "REVIEWING"), [drafts]);

  return <Grid container spacing={2} sx={{padding: 2}}>
    {filtered.map((s, i) => <Grid key={`pending${i}`} item xs={12} sm={6} lg={4}>
      <Card variant="outlined" sx={{ borderRadius: '24px' }}>
        <CardContent>
          <Typography variant="h7" component="div">{s.name || "New"}</Typography>
          <Typography variant="caption" component="div">{new Date(s.modified).toLocaleDateString() || null}</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => onView(s)} size="small">Details</Button>
          {s.original_id ? <EditOutlinedIcon sx={{marginLeft: 'auto', width: '18px'}} /> : <NewReleasesOutlinedIcon sx={{marginLeft: 'auto', width: '18px'}} />}
        </CardActions>
      </Card>
    </Grid>)}
  </Grid>;
};
