import { useState } from "react";

const validateProperty = (options, value) => {
  if (!options) return [null, value];
  if (value === undefined && !options.validateUndef) {
    const newOptions = {...options, invalid: false}
    return [newOptions, value];
  }

  if (options.type == "number") {
    value = Number(value);
    if (isNaN(value))
      return [options, undefined];
  } else if (options.type == "boolean")
    value = Boolean(value);
  const newOptions = {...options}
  const validator = options.validator;
  if (!validator)
    newOptions.invalid = false;
  else {
    if (Array.isArray(validator))
      newOptions.invalid = Boolean(validator.find(v => !v(value)));
    else
      newOptions.invalid = !validator(value);
  }
  return [newOptions, value];
};

export const useValidation = (options) => {
  const [validation, setValidation] = useState(options ? {...options} : {});

  const isValid = (property) => {
    if (property) return !validation[property]?.invalid;

    for (const [property, validationOption] of Object.entries(validation)) {
      if (property != "_modified" && validationOption.invalid) return false;
    }
    return true;
  };

  const isModified = () => Boolean(validation._modified);

  const validate = (property, value) => {
    if (!property) {
      if (!validation._modified) setValidation({...validation, _modified: true});
      return value;
    }
    [options, value] = validateProperty(validation[property], value);
    if (value === undefined) return undefined;
    if (options) {
      setValidation({...validation, [property]: options, _modified: true});
    } else {
      setValidation({...validation, _modified: true});
    }
    return value;
  };

  const validateMultiple = (object, properties, customValidator) => {
    const newValidation = {...validation, _modified: true};
    if (!properties) properties = Object.keys(object);
    for (let i = 0; i < properties.length; i++) {
      const property = properties[i];
      const [options, value] = validateProperty(validation[property], object[property]);
      if (options && customValidator) {
        const invalid = customValidator(property, object[property]);
        if (invalid != null)
          options.invalid = invalid;
      }
      if (options)
        newValidation[properties[i]] = options;
    }
    setValidation(newValidation);
  };

  const init = (object) => {
    const newValidation = {...validation};
    let invalid = false;
    for (const property of Object.keys(validation)) {
      if (property == "_modified") continue;
      const [options, value] = validateProperty(validation[property], _get_property(object, property));
      if (options && options.invalid) {
        //newValidation._modified = true;
        invalid = true;
        newValidation[property] = options;
      }
    }
    if (invalid || newValidation._modified) {
      delete newValidation._modified;
      setValidation(newValidation);
    }
  };

  const reset = () => {
    const newValidation = {};
    for (const [key, options] of Object.entries(validation)) {
      if (key == "_modified") continue;
      const newOptions = {...options};
      delete newOptions.invalid;
      newValidation[key] = newOptions;
    }
    setValidation(newValidation);
  }

  const modifiedProperties = (object) => {
    if (object == null || !validation._modified) return {};
    const changedValues = {};
    for (const [key, options] of Object.entries(validation)) {
      if (key == "_modified" || !("invalid" in options)) continue;
      const value = _get_property(object, key);
      if (value == undefined) continue;

      if (key.indexOf(".")) {
        const path = key.split(".");
        let o = object, changed = changedValues;
        for (let p = 0; p < path.length - 1; p ++) {
          o = o[path[p]];
          changed[path[p]] = o;
          changed = changed[path[p]];
        }
        changed[path[path.length - 1]] = value;
      } else
        changedValues[key] = value;
    }
    return changedValues;
  };

  return { init, isValid, isModified, validate, validateMultiple, modifiedProperties, reset };
};

const _get_property = (object, property) => {
  if (!property.indexOf(".")) return object[property];
  const path = property.split(".");
  let o = object;
  for (let p = 0; p < path.length; p ++) {
    o = o[path[p]];
    if (o == undefined) return o;
  }
  return o;
}

export const ID_VALIDATOR = (value) => /^[a-z][a-z\d_.\-]+$/.test(value);

export const NAME_VALIDATOR = (value) => {
  const trimmed = value?.trim();
  return value?.length == trimmed?.length && trimmed?.length >= 2 && trimmed?.length <= 32;
}

export const NOT_EMPTY_VALIDATOR = (value) => {
  if (value == null) return false;
  switch(typeof value) {
    case "string": return value.trim().length > 0;
    case "object": return Array.isArray(value) ? value.length > 0 : Object.keys(value).length > 0;
    default: return true;
  }
};

export const NUMBER_VALIDATOR = (min, max) => (value) => max ? value >= min && value <= max : value <= min;

export const LENGTH_VALIDATOR = (min, max) => (value) => {
  const l = value?.length || 0;
  return max ? l >= min && l <= max : l <= min;
}

const emailRegex = /^.+?@[^@]+?\.[^@]+$/;
export const EMAIL_VALIDATOR = (value) => (!value?.length || emailRegex.test(value));

const httpsUrlRegex = /^(https:\/\/)([\w-]+(\.[\w-]+)+\/?)([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;
export const URL_VALIDATOR = (value) => (!value?.length || httpsUrlRegex.test(value));

export const setNestedProperty = (obj, path, value) => {
  const keys = path.split('.');
  const lastKey = keys.pop();

  keys.reduce((acc, key) => {
    acc[key] = acc[key] || {};
    return acc[key];
  }, obj)[lastKey] = value;
  return obj;
};
