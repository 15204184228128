
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from '@mui/material';
import { displayErrorMessage } from "../../redux/modules/messages";
import { usePrompt } from "../../hooks/prompt";
import { useConfirm } from "../../hooks/confirm";
import { useDraftApi } from "../../hooks/draftApi";
import { useServiceApi } from "../../hooks/serviceApi";
import { ViewService, EditDraft } from "../common/service";
import { Tabs, Tab, FormRow } from "../widgets/customComponents";

const editingActions = (draft, validation, onNewDraft) => {
  const history = useHistory();
  const confirm = useConfirm();
  const prompt = usePrompt();
  const draftApi = useDraftApi();

  const handleDelete = () =>
    confirm(null,
      "Discard changes?",
      () => draftApi.deleteService(draft.id, () => history.replace(draft.original_id ? `/services/${draft.original_id}` : "/services"))
    );

  const handleSave = () =>
    draftApi.updateService(draft.id, validation.modifiedProperties(draft), onNewDraft);

  const handleSubmit = () =>
    prompt("Submit for publication?",
      {label: "Comment (optional)", action: "Submit", validator: value => true},
      (comment) => draftApi.submitService(draft.id, comment, onNewDraft)
    );

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-save"
      onClick={handleSave}
      disableElevation
      disabled={!validation.isModified()}
    >
      Save Draft
    </Button>
    <Button
      data-cy="button-submit"
      onClick={handleSubmit}
      disableElevation
      disabled={validation.isModified() || !validation.isValid()}
    >
      Submit for Review
    </Button>
    <Button
      data-cy="button-delete"
      onClick={handleDelete}
      disableElevation
    >
      {!draft.original_id ? "Cancel Registration" : "Abandon Changes"}
    </Button>
  </FormRow>;
};

const reviewingActions = (draft, onNewDraft) => {
  const confirm = useConfirm();
  const draftApi = useDraftApi();

  const handleRetract = () =>
    confirm(null,
      "Retract draft?",
      () => draftApi.retractService(draft.id, onNewDraft)
    );

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-retract"
      onClick={handleRetract}
      disableElevation
     >
      Retract submission
    </Button>
  </FormRow>;
};

const publishedActions = (service, onChange) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const prompt = usePrompt();
  const serviceApi = useServiceApi();
  const draftApi = useDraftApi();

  const handleDelete = () =>
    prompt("Unregister and delete?",
      {label: "Type 'UNREGISTER' to confirm", action: "Confirm"},
      (verif) => {
        if (verif == "UNREGISTER")
          () => serviceApi.deleteService(service.id, () => history.replace("/services"));
        else
          dispatch(displayErrorMessage("Un-registration not confirmed"));
      }
    );

  const handleReedit = () =>
    confirm(null,
      "Editing details will require trigger a new review process",
      () => draftApi.reeditService(service.id, () => history.push(`/services/${service.id}/draft`))
    );

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-edit"
      onClick={handleReedit}
      disabled={!service.enabled || Boolean(service.draft_state)}
      disableElevation
    >
      Make Changes
    </Button>
    <Button
      data-cy="button-delete"
      onClick={handleDelete}
      disabled={!service.enabled}
      disableElevation
    >
      Cancel Registration
    </Button>
  </FormRow>;
};

export default ({isDraft}) => {
  const { serviceId } = useParams();
  const history = useHistory();
  const draftApi = useDraftApi();
  const serviceApi = useServiceApi();
  const [tab, setTab] = useState(null);
  const [service, setService] = useState(null);

  useEffect(() => {
    if (isDraft) {
      draftApi.getService(serviceId, (draft) => {
        if (!draft) {
          history.replace(`/services/${serviceId}`);
          return;
        }
        setTab("draft");
        setService(draft);
      });
    } else {
      serviceApi.getService(serviceId, (service) => {
        setTab("published");
        setService(service);
      });
   }
  }, [isDraft]);

  const handleTabChange = (e, newTab) => {
    setTab(null);
    if (newTab == "published")
      history.replace(`/services/${serviceId}`);
    else
      history.replace(`/services/${serviceId}/draft`);
  };

  if (!service || !tab) return null;

  return <>
    {(service.original_id || service.draft_state || null) &&
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab value="published" label="Published" />
        <Tab value="draft" label="Draft" />
      </Tabs>
    }
    {tab == "published"
      ? <ViewService service={service} actions={publishedActions} onChange={setService} />
      : service.review_state == "REVIEWING"
        ? <ViewService service={service} actions={reviewingActions} onChange={setService} />
        : <EditDraft draft={service} actions={editingActions} onChange={setService} /> || null
    }
  </>;
};
