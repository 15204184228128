import { useDispatch } from "react-redux";
import { openModal, closeModal } from "../redux/modules/modal";

export const usePrompt = () => {
  const dispatch = useDispatch();

  const showModal = (title, options, onPrompt) =>
    dispatch(
      openModal({
        componentPath: "components/widgets/PromptModal",
        componentParams: { title, options, onPrompt },
        modalSettings: { maxWidth: "xs" },
      })
    );
  return showModal;
};
