
import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, Card, CardActions, CardHeader, CardContent, Typography, Chip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Badge } from '@mui/material';
import { useDraftApi } from "../../hooks/draftApi";
import { useServiceApi } from "../../hooks/serviceApi";
import { useConfirm } from "../../hooks/confirm";
import {Tabs, Tab} from "../widgets/customComponents";
import { Services, Drafts } from "./services"


export default () => {
  const history = useHistory();
  const confirm = useConfirm();
  const draftApi = useDraftApi();
  const serviceApi = useServiceApi();
  const [tab, setTab] = useState("active");
  const [services, setServices] = useState(null);
  const [drafts, setDrafts] = useState(null);
  const [counts, setCounts] = useState(null);

  useEffect(() => {
    if (services) return;

    serviceApi.listServices((services) => {
      var newCounts = {active: 0, disabled: 0, experimental: 0, EDITING: 0, REVIEWING: 0, REJECTED: 0};
      services.forEach(p => {
        const type = !p.enabled ? "disabled" : p.experimental ? "experimental" : "active";
        newCounts[type] += 1;
      });
      setCounts(newCounts);
      setServices(services);
      draftApi.listServices((drafts) => {
        drafts.forEach(s => newCounts[s.review_state] += 1);
        setCounts(newCounts);
        setDrafts(drafts);
      });
    });
  }, [Boolean(services)]);

  const handleTabChange = (e, newTab) => setTab(newTab);
  const handleView = (s) => history.push(`/services/${s.id}`);
  const handleReview = (s) => history.push(`/services/${s.id}/draft`);

  const handleCreateAction = () =>
    confirm(null,
      "Create new service?",
      () => draftApi.createService({display_name: "New"}, (s) => history.push(`/services/${s.id}/draft`))
    );

  if (services == null && drafts == null) return;

  return <>
    <Tabs value={tab} onChange={handleTabChange}>
      <Tab value="active" label={`Published (${counts?.active || 0})`} />}
      {(counts?.experimental || null) && <Tab value="experimental" label={`Experimental (${counts.experimental})`} />}}
      {(counts?.disabled || null) && <Tab value="disabled" label={`Disabled (${counts.disabled})`} />}}
      {(counts?.REVIEWING || null) && <Tab value="reviewing" label={`Pending approval (${counts.REVIEWING})`} />}
    </Tabs>

    {(tab == "active" || tab == "experimental" || tab == "disabled")
      ? <Services services={services || []} state={tab} onCreate={handleCreateAction} onView={handleView} />
      : <Drafts drafts={drafts} onView={handleReview} />
    }
  </>;
};
