
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from '@mui/material';
import { useAuth } from "../../AuthContext";
import { displayInfoMessage, displayErrorMessage } from "../../redux/modules/messages";
import { usePrompt } from "../../hooks/prompt";
import { useConfirm } from "../../hooks/confirm";
import { useDraftApi } from "../../hooks/draftApi";
import { useParticipantApi } from "../../hooks/participantApi";
import { useValidation } from "../../hooks/validation";
import { ViewParticipant, EditDraft } from "../common/participant";
import { Tabs, Tab, Form, FormRow } from "../widgets/customComponents";


const editingActions = (draft, validation, onNewDraft) => {
  const history = useHistory();
  const confirm = useConfirm();
  const prompt = usePrompt();
  const draftApi = useDraftApi();

  const handleDelete = () =>
    confirm(null,
      draft.original_id ? "Abandon changes?" : "Forfeit registration?",
      () => draftApi.deleteParticipant(draft.id, () => {
        if (draft.original_id)
          history.replace("/myaccount");
        else
          window.location.href = "/api/auth/logout";
      })
    );

  const handleSave = () =>
    draftApi.updateParticipant(draft.id, validation.modifiedProperties(draft), () =>
      validation.reset()
    );

  const handleSubmit = () =>
    prompt("Submit for publication?",
      {label: "Comment (optional)", action: "Submit", validator: value => true},
      (comment) => draftApi.submitParticipant(comment, onNewDraft)
    );

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-save"
      onClick={handleSave}
      disableElevation
      disabled={!validation.isModified()}
    >
      Save Draft
    </Button>
    <Button
      data-cy="button-submit"
      onClick={handleSubmit}
      disableElevation
      disabled={validation.isModified() || !validation.isValid()}
    >
      Submit for Review
    </Button>
    <Button
      data-cy="button-delete"
      onClick={handleDelete}
      disableElevation
    >
      {draft.original_id ? "Abandon Changes" : "Forfeit Registration"}
    </Button>
  </FormRow>;
};

const reviewingActions = (draft, onNewDraft) => {
  const confirm = useConfirm();
  const draftApi = useDraftApi();

  const handleRetract = () =>
    confirm(null,
      "Retract draft?",
      () => draftApi.retractParticipant(onNewDraft)
    );

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-retract"
      onClick={handleRetract}
      disableElevation
     >
      Retract Submission
    </Button>
  </FormRow>;
};

const publishedActions = (participant, onChange) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const prompt = usePrompt();
  const participantApi = useParticipantApi();
  const draftApi = useDraftApi();

  const handleDelete = () =>
    prompt("Unregister and disable all services?",
      {label: "Type 'UNREGISTER' to confirm", action: "Confirm"},
      (verif) => {
        if (verif == "UNREGISTER")
          () => participantApi.deleteParticipant(participant.id, () => window.location.href="/api/auth/logout");
        else
          dispatch(displayErrorMessage("Un-registration not confirmed"));
      }
    );

  const handleReedit = () =>
    confirm(null,
      "Editing details will require trigger a new review process",
      () => draftApi.reeditParticipant(() => history.push("/myaccount/draft"))
    );

  const handleGetCredentials = () =>
    participantApi.getCredentials(participant.id, (credentials) => {
      navigator.clipboard.writeText(`Token URL: ${credentials.oauth2_token_url}\nClient ID: ${credentials.client_id}\nClient Secret: ${credentials.client_secret}`);
      dispatch(displayInfoMessage("Credentials copied to clipboard"));
    });

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-edit"
      onClick={handleReedit}
      disabled={!participant.enabled || Boolean(participant.draft_state)}
      disableElevation
    >
      Make Changes
    </Button>
    <Button
      data-cy="button-credentials"
      onClick={handleGetCredentials}
      disabled={!participant.enabled}
      disableElevation
    >
      OAuth2 Credentials
    </Button>
    <Button
      data-cy="button-delete"
      onClick={handleDelete}
      disabled={!participant.enabled}
      disableElevation
    >
      Cancel Registration
    </Button>
  </FormRow>;
};

export default ({isDraft}) => {
  const history = useHistory();
  const { profile } = useAuth();
  const draftApi = useDraftApi();
  const participantApi = useParticipantApi();
  const [tab, setTab] = useState(null);
  const [participant, setParticipant] = useState(null);

  useEffect(() => {
    if (isDraft) {
      draftApi.getMyParticipant((draft) => {
        if (!draft) {
          history.replace("/myaccount");
          return;
        }
        setTab("draft");
        setParticipant(draft);
      });
    } else {
      participantApi.getParticipant(profile.participant.id, (participant) => {
        setTab("published");
        setParticipant(participant);
      });
   }
  }, [isDraft]);

  const handleTabChange = (e, newTab) => {
    setTab(null);
    if (newTab == "published")
      history.replace("/myaccount");
    else
      history.replace("/myaccount/draft");
  };

  if (!participant || !tab) return null;

  return <>
    {(participant.original_id || participant.draft_state || null) &&
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab value="published" label="Published" />
        <Tab value="draft" label="Draft" />
      </Tabs>
    }
    {tab == "published"
      ? <ViewParticipant participant={participant} actions={publishedActions} onChange={setParticipant} />
      : participant.review_state == "REVIEWING"
        ? <ViewParticipant participant={participant} actions={reviewingActions} onChange={setParticipant} />
        : <EditDraft draft={participant} actions={editingActions} onChange={setParticipant} /> || null
    }
  </>;
};
