
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from '@mui/material';
import { usePrompt } from "../../hooks/prompt";
import { useConfirm } from "../../hooks/confirm";
import { useDraftApi } from "../../hooks/draftApi";
import { useServiceApi } from "../../hooks/serviceApi";
import { Tabs, Tab, FormRow } from "../widgets/customComponents";
import { ViewService, EditDraft } from "../common/service";


const publishedActions = (service, onChange) => {
  const history = useHistory();
  const confirm = useConfirm();
  const serviceApi = useServiceApi();

  const handleDelete = () =>
    confirm(null,
      "Irreversibly delete service?",
      () => serviceApi.deleteService(service.id, () => history.replace("/services"))
    );

  const handleEnablement = () =>
    confirm(null,
      service.enabled ? "Disable service?" : "Enable service?",
      () => serviceApi.setServiceEnablement(service.id, !service.enabled, onChange)
    );

  const handleExperimental = () =>
    confirm(null,
      service.experimental ? "Make available to everyone?" : "Restrict access?",
      () => serviceApi.setServiceExperimental(service.id, !service.experimental, onChange)
    );

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-experimental"
      onClick={handleExperimental}
      disabled={service.experimental && Boolean(service.draft_state)}
      disableElevation
    >
      {service.experimental ? "Make Regular" : "Make Experimental"}
    </Button>
    <Button
      data-cy="button-enablement"
      onClick={handleEnablement}
      disableElevation
    >
      {service.enabled ? "Disable" : "Enable"}
    </Button>
    {(!service.enabled || null) && <Button
      data-cy="button-delete"
      onClick={handleDelete}
      disableElevation
    >
      Permanently Delete
    </Button>}
  </FormRow>
};

const viewDraftActions = (draft, onChange) => {
  const history = useHistory();
  const draftApi = useDraftApi();
  const confirm = useConfirm();
  const prompt = usePrompt();

  const handleDelete = () =>
    confirm(
      null,
      "Discard participant's changes and delete draft?",
      () => draftApi.deleteService(draft.id, () => {
        history.replace(`/services/${draft.id}`);
        onChange(null);
      })
    );

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-delete"
      onClick={handleDelete}
      disableElevation
    >
      Delete Un-submitted Draft
    </Button>
  </FormRow>;
};

const editDraftActions = (draft, validation, onNewDraft) => {
  const history = useHistory();
  const draftApi = useDraftApi();
  const confirm = useConfirm();
  const prompt = usePrompt();

  const handleSave = () =>
    draftApi.updateService(draft.id, validation.modifiedProperties(draft), onNewDraft);

  const handleApprove = () =>
    confirm(
      "Approve Changes?",
      `Are you sure you publish changes to the registry?`,
      () => draftApi.approveService(draft.id, (service) =>
        history.replace(`/services/${draft.id}`))
    );

  const handleReject = () =>
    prompt("Reject submission (and discard unsaved changes)?", {label: "Review comment"}, (comment) =>
      draftApi.rejectService(draft.id, comment, () => history.replace("/services"))
    );

  return <FormRow style={{marginTop: '30px'}}>
    <Button
      data-cy="button-save"
      onClick={handleSave}
      disableElevation
      disabled={!validation.isModified()}
    >
      Save Changes
    </Button>
    <Button
      data-cy="button-approve"
      onClick={handleApprove}
      disableElevation
      disabled={validation.isModified() || !validation.isValid()}
    >
      {draft.original_id ? "Approve Changes" : "Approve New Service"}
    </Button>
    <Button
      data-cy="button-reject"
      onClick={handleReject}
      disableElevation
    >
      Reject Submission
    </Button>
  </FormRow>;
};

export default ({isDraft}) => {
  const history = useHistory();
  const { serviceId } = useParams();
  const draftApi = useDraftApi();
  const serviceApi = useServiceApi();
  const [tab, setTab] = useState(null);
  const [service, setService] = useState(null);

  useEffect(() => {
    if (isDraft) {
      draftApi.getService(serviceId, (draft) => {
        if (!draft) {
          history.replace(`/services/${serviceId}`);
          return;
        }
        setTab("review");
        setService(draft);
      });
    } else {
      serviceApi.getService(serviceId, (service) => {
        setTab("published");
        setService(service);
      });
   }
  }, [isDraft]);

  const handleTabChange = (e, newTab) => {
    setTab(null);
    if (newTab == "published")
      history.replace(`/services/${serviceId}`);
    else
      history.replace(`/services/${serviceId}/draft`);
  };

  if (!service || !tab) return null;

  return <>
    {(service.original_id || Boolean(service.draft_state) || null) &&
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab value="published" label="Published" />
        <Tab value="review" label={service.draft_state == "REVIEWING" ? "Review" : "Draft"} />
      </Tabs>
    }
    {tab == "published"
      ? <ViewService service={service} actions={publishedActions} onChange={setService} />
      : service.review_state == "REVIEWING"
        ? <EditDraft draft={service} actions={editDraftActions} onChange={setService} />
        : <ViewService service={service} actions={viewDraftActions} onChange={setService} />
    }
  </>;
};
