
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Alert, TextField, Button, Typography } from '@mui/material';
import { useAuth } from "../../AuthContext";
import {Form, FormRow} from "../widgets/customComponents";
import { useValidation, LENGTH_VALIDATOR, EMAIL_VALIDATOR, setNestedProperty } from "../../hooks/validation";

export const ViewParticipant = ({participant, actions, onChange}) => <Form>
  {(!participant.enabled && !participant.review_state || null) &&
    <Alert severity="error">This participant is disabled.</Alert>
  }
  {(participant.enabled && participant.untrusted || null) &&
    <Alert severity="warning">This participant is currently untrusted.</Alert>
  }
  <Typography variant="h4">{participant.display_name || "-"}</Typography>
  <FormRow>
    <Typography variant="caption">{participant.organization}</Typography>
      <Typography variant="caption" sx={{marginLeft: 'auto'}}>
        Last modified on: {new Date(participant.modified).toLocaleDateString()}
      </Typography>
  </FormRow>
  <TextField
    multiline={true}
    minRows={3}
    label="Address"
    defaultValue={participant.admin_details?.address || "-"}
    InputProps={{readOnly: true, disableUnderline: true }}
    margin="dense"
    variant="standard"
    fullWidth
  />
  <FormRow>
    <TextField
      label="Country"
      defaultValue={participant.country || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
      sx={{width: '50%'}}
    />
    <TextField
      label="VAT #"
      defaultValue={participant.admin_details?.vat || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
      sx={{width: '50%'}}
    />
  </FormRow>
  <TextField
    label="Contact Person"
    defaultValue={participant.admin_details?.contact_person || "-"}
    InputProps={{readOnly: true, disableUnderline: true }}
    margin="dense"
    variant="standard"
    fullWidth
  />
  <FormRow>
    <TextField
      label="Phone"
      defaultValue={participant.admin_details?.contact_phone || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
      sx={{width: '50%'}}
    />
    <TextField
      label="Email"
      defaultValue={participant.admin_details?.contact_email || "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
      sx={{width: '50%'}}
    />
  </FormRow>
  <FormRow>
    <TextField
      label="ATLAS Oauth2 Redirect URIs (comma-separated) - required for service consumers"
      defaultValue={participant.oauth2_redirect_uris?.length ? participant.oauth2_redirect_uris.join(",") : "-"}
      InputProps={{readOnly: true, disableUnderline: true }}
      margin="dense"
      variant="standard"
      fullWidth
    />
  </FormRow>
  {(Boolean(actions) || null) && actions(participant, onChange)}
</Form>;

const VALIDATION_OPTIONS = {
  organization: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(3, 100)
  },
  display_name: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(3, 100)
  },
  country: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(1, 50)
  },
  "admin_details.address": {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(1, 300)
  },
  "admin_details.contact_person": {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(1, 100)
  },
  "admin_details.contact_phone": {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(1, 100)
  },
  "admin_details.contact_email": {
    validateUndef: true,
    validator: [LENGTH_VALIDATOR(1, 100), EMAIL_VALIDATOR]
  },
  "admin_details.vat": {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(1, 100)
  },
  oauth2_redirect_uris: {
    validateUndef: true,
    validator: LENGTH_VALIDATOR(0, 1000)
  }
};

export const EditDraft = ({draft: original, actions, onChange}) => {
  const { profile } = useAuth();
  const isAdmin = profile.roles.includes("ADMIN");
  const validation = useValidation(isAdmin ? VALIDATION_OPTIONS : {...VALIDATION_OPTIONS, organization: {}});
  const [draft, setDraft] = useState({...original});

  useEffect(() => validation.init(draft), []);

  const handlePropChange = (prop, v) => {
    const value = validation.validate(prop, v);
    if (value === undefined) return;
    const newDraft = {...draft};
    setNestedProperty(newDraft, prop, value);
    setDraft(newDraft);
  };

  const handleChange = (e) => {
    handlePropChange(e.target.name, e.target.value);
  };

  const handleChangeRedirectUris = (e) => {
    handlePropChange(e.target.name, e.target.value.split(","));
  };

  const handleNewDraft = (draft) => {
    validation.reset();
    onChange(draft);
  }

  const inReview = draft.review_state == 'REVIEWING';
  const isNew = !draft.original_id;
  const isUntrusted = Boolean(draft.untrusted);

  return <Form>
    {(isNew && !isAdmin || null) && <Alert severity="info">Welcome to AgriCircle ATLAS. Please fill this form and submit to complete registration</Alert>}
    {(draft.review_state == "REJECTED" || null) && <Alert severity="warning">
      Your submission has been rejected: {draft.review_comment || "unknown reason"}
    </Alert>}
    <TextField
      name="organization"
      label={`Participant Identifier${isUntrusted ? " (currently untrusted)" : ""}`}
      value={draft.organization || ""}
      onChange={handleChange}
      InputProps={{readOnly: (!isNew && !isUntrusted) || !isAdmin, disableUnderline: (!isNew && !isUntrusted) || !isAdmin }}
      margin="dense"
      variant="standard"
      fullWidth
    />
    <TextField
      name="display_name"
      label="Display Name"
      value={draft.display_name || ""}
      onChange={handleChange}
      error={!validation.isValid("display_name")}
      margin="dense"
      variant="standard"
      fullWidth
    />
    <TextField
      name="admin_details.address"
      multiline={true}
      minRows={3}
      label="Address (street, city, postal code, ...)"
      value={draft.admin_details?.address || ""}
      onChange={handleChange}
      error={!validation.isValid("address")}
      margin="dense"
      variant="standard"
      fullWidth
    />
    <FormRow>
      <TextField
        name="country"
        label="Country"
        value={draft.country || ""}
        onChange={handleChange}
        error={!validation.isValid("country")}
        margin="dense"
        variant="standard"
        fullWidth
      />
      <TextField
        name="admin_details.vat"
        label="VAT #"
        value={draft.admin_details?.vat || ""}
        onChange={handleChange}
        error={!validation.isValid("vat")}
        margin="dense"
        variant="standard"
        fullWidth
      />
    </FormRow>
    <TextField
      name="admin_details.contact_person"
      label="Contact Person"
      value={draft.admin_details?.contact_person || ""}
      onChange={handleChange}
      error={!validation.isValid("contact_person")}
      margin="dense"
      variant="standard"
      fullWidth
    />
    <FormRow>
      <TextField
        name="admin_details.contact_phone"
        label="Contact Phone"
        value={draft.admin_details?.contact_phone || ""}
        onChange={handleChange}
        error={!validation.isValid("contact_phone")}
        margin="dense"
        variant="standard"
        fullWidth
      />
      <TextField
        name="admin_details.contact_email"
        label="Contact E-mail"
        value={draft.admin_details?.contact_email || ""}
        onChange={handleChange}
        error={!validation.isValid("contact_email")}
        margin="dense"
        variant="standard"
        fullWidth
      />
    </FormRow>
    <FormRow>
      <TextField
        name="oauth2_redirect_uris"
        label="ATLAS Oauth2 Redirect URIs (comma-separated) - required for service consumers"
        value={draft.oauth2_redirect_uris?.length ? draft.oauth2_redirect_uris.join(",") : ""}
        onChange={handleChangeRedirectUris}
        error={!validation.isValid("oauth2_redirect_uris")}
        margin="dense"
        variant="standard"
        fullWidth
      />
    </FormRow>
    {actions(draft, validation, handleNewDraft)}
  </Form>
};
