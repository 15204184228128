import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Button } from "@mui/material";
import { useAuth } from "../../AuthContext";
import { useUserApi } from "../../hooks/userApi";
import { displayInfoMessage } from "../../redux/modules/messages";
import { openModal } from "../../redux/modules/modal";
import {Form, FormRow} from "../widgets/customComponents";


export default () => {
  const dispatch = useDispatch();
  const { profile } = useAuth();
  const userApi = useUserApi();

  const handleChangePassword = () => {
    dispatch(
      openModal({
        componentPath: "components/widgets/ChangePasswordModal",
        componentParams: {
          onChange: (currentPassword, newPassword) => {
            userApi.changePassword(currentPassword, newPassword, () => dispatch(displayInfoMessage("Password changed")));
          }
        },
        modalSettings: { maxWidth: "xs" }
      })
    );
  };

  if (!profile) return null;

  return <>
    <Form>
      <FormRow>
        <TextField
          label="Name"
          defaultValue={profile.name}
          InputProps={{readOnly: true, disableUnderline: true }}
          margin="dense"
          variant="standard"
          fullWidth
        />
        <Button size="small" onClick={handleChangePassword} sx={{width:'200px'}}>
          Change Password
        </Button>
      </FormRow>
      {profile.roles?.includes("ADMIN")
        ? <TextField
            label="Role"
            defaultValue="Atlas Administrator"
            InputProps={{readOnly: true, disableUnderline: true }}
            margin="dense"
            variant="standard"
            fullWidth
          />
        : <TextField
            label="Participant"
            defaultValue={profile?.participant?.display_name || "*REGISTRATION IN PROGRESS*"}
            InputProps={{readOnly: true, disableUnderline: true }}
            margin="dense"
            variant="standard"
            fullWidth
          />
      }
    </Form>
  </>;
};
