
import { Route } from "react-router-dom";

export const PrivateRoute = ({ profile, children, ...rest}) => {
  if (!profile) return null;
  return <Route {...rest}>{children}</Route>;
};

export const AdminRoute = ({ profile, children, ...rest}) => {
  if (!profile) return null;
  return !profile.participant ? <Route {...rest}>{children}</Route> : "Not authorized";
};

export const UserRoute = ({ profile, tool, children, ...rest}) => {
  if (!profile) return null;
  return profile.participant ? <Route {...rest}>{children}</Route> : "Only for participants";
};
