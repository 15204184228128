
import React, { useMemo } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, Card, CardActions, CardHeader, CardContent, Typography } from '@mui/material';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const Participants = ({participants, state, onView}) => {
  const filtered = useMemo(() => {
    if (participants == null) return [];
    return participants.filter(p => {
      switch (state) {
        case "disabled": return !p.enabled;
        case "active": return p.enabled && !p.untrusted;
        case "untrusted": return p.enabled && p.untrusted;
      }
    });
  }, [participants, state]);

  return <TableContainer>
    <Table size="small">
      <colgroup>
        <col width="250" />
        <col />
        <col width="50" />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(!filtered?.length || null) && <TableRow>
          <TableCell colSpan={3}>No active participants</TableCell>
        </TableRow>}
        {(filtered?.length || null) && filtered.map((p, i) => <TableRow key={`approved${i}`}>
          <TableCell>{p.organization}</TableCell>
          <TableCell>{p.display_name}</TableCell>
          <TableCell><Button size="small" onClick={() => onView(p)}>Details</Button></TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  </TableContainer>;
};

export const Drafts = ({drafts, onView}) => {
  const filtered = useMemo(() => drafts.filter(p => p.review_state == "REVIEWING"), [drafts]);

  return <Grid container spacing={2} sx={{padding: 2}}>
    {filtered.map((p, i) => <Grid key={`pending${i}`} item xs={12} sm={6} lg={4}>
      <Card variant="outlined" sx={{ borderRadius: '24px' }}>
        <CardContent>
          <Typography variant="h7">{p.organization || "New"}</Typography>
          <Typography variant="caption" component="div">{new Date(p.modified).toLocaleDateString() || null}</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => onView(p)} size="small">Details</Button>
          {p.original_id ? <EditOutlinedIcon sx={{marginLeft: 'auto', width: '18px'}} /> : <NewReleasesOutlinedIcon sx={{marginLeft: 'auto', width: '18px'}} />}
        </CardActions>
      </Card>
    </Grid>)}
  </Grid>;
};
