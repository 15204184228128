/**
 * Create the store with asynchronously loaded reducers
 */
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducer } from "./modules/reducer";

/**
 * Return configured store
 * @return {Object} - configured store
 */
export default function configureStore() {
  const enhancers = [applyMiddleware(thunkMiddleware)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  // @ts-ignore
  const composeEnhancers = process.env.NODE_ENV !== "production" && typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    // @ts-ignore
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose; // prettier-ignore
  /* eslint-enable */

  const store = createStore(reducer, composeEnhancers(...enhancers));
  // @ts-ignore
  if (process.env.NODE_ENV !== "production" && module.hot) {
    // @ts-ignore
    module.hot.accept("./modules/reducer", () => {
      store.replaceReducer(require("./modules/reducer")); // eslint-disable-line
    });
  }

  return store;
}
